import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const CssTextField = styled(TextField, {
    shouldForwardProp: (prop) =>
        prop !== 'fixedPadding' &&
        prop !== 'hasBackground' &&
        prop !== 'disabledStyling' &&
        prop !== 'focusedStyling' &&
        prop !== 'hoverStyling'
})(({ fixedPadding, hasBackground, disabledStyling, focusedStyling, hoverStyling, theme }) => {
    const darkMode = theme.palette.mode === 'dark';
    const backgroundColor = darkMode ? theme.palette.background.default : '#EDF2F8';
    const boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)';
    const borderColor = darkMode ? theme.palette.grey[500] : '#EDF2F8';
    const hoverBorderColor = darkMode ? theme.palette.primary.main : '#EDF2F8';
    const focusedBackgroundColor = darkMode ? '#FFD78A' : '#FFECBA';
    const focusedBorderColor = darkMode ? '#E0B34A' : '#FFD35E';
    const disabledBackgroundColor = darkMode ? theme.palette.background.paper : '#EAEAEA';
    const disabledBorderColor = darkMode ? theme.palette.grey[700] : '#D9D9D9';
    const errorBackgroundColor = '#FFF2F2';
    const errorBorderColor = '#D10029';
    const labelColor = darkMode ? theme.palette.text.secondary : 'gray';
    const textColor = theme.palette.text.primary;
    const focusedTextColor = theme.palette.common.black;

    return {
        '& .MuiInput-input': {
            padding: 0,
            ':focus': {
                color: 'black'
            }
        },
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: darkMode ? theme.palette.text.disabled : 'rgba(0, 0, 0, 1)'
        },
        '& .MuiInput-root': {
            backgroundColor,
            boxShadow,
            borderRadius: '10px',
            padding: fixedPadding ?? 10,
            border: `2px solid ${borderColor}`,
            '&:before': {
                borderBottom: 'none!important',
                '&:hover': {
                    borderBottom: 'none!important'
                }
            },
            '&:hover': {
                boxShadow,
                // border: `2px solid ${hoverBorderColor}`,
                ...hoverStyling,
                '&:before': { borderBottom: 'none!important' }
            },
            '&.Mui-focused': {
                background: focusedBackgroundColor,
                boxShadow,
                // border: `2px solid ${focusedBorderColor}`, // Using adjusted focused color,
                ...focusedStyling
            },
            '&.Mui-disabled': {
                background: disabledBackgroundColor,
                border: `2px solid ${disabledBorderColor}`,
                boxShadow,
                ...disabledStyling
            },
            '&.Mui-disabled:before': {
                borderBottom: 'none'
            },
            '&.Mui-error': {
                background: errorBackgroundColor,
                border: `2px solid ${errorBorderColor}`
            },
            '&.Mui-focused fieldset': {
                borderColor: focusedBorderColor // Using adjusted focused color,
            },
            '&:.Mui-focused': {
                color: focusedTextColor
            }
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor,
            boxShadow,
            borderRadius: '10px',
            padding: fixedPadding ?? 0,
            '& label': {
                color: labelColor
            },
            '& > fieldset': {
                borderColor
            },
            '&:hover': {
                boxShadow,
                ...hoverStyling,
                '& > fieldset': {
                    border: `2px solid ${hoverBorderColor}`
                }
            },
            '&.Mui-focused': {
                background: focusedBackgroundColor,
                boxShadow,
                ...focusedStyling,
                '& > fieldset': {
                    border: `2px solid ${focusedBorderColor}` // Using adjusted focused color
                }
            },
            '&.Mui-error': {
                background: errorBackgroundColor,
                '& > fieldset': {
                    border: `2px solid ${errorBorderColor}`
                }
            },
            '&.Mui-disabled': {
                background: disabledBackgroundColor,
                '& > fieldset': {
                    border: `2px solid ${disabledBorderColor}`,
                    ...disabledStyling
                },
                boxShadow
            }
        }
    };
});
