import axiosInstance from '../axiosInstance';

export const clubApi = {
    post: (path, params, cancelToken) =>
        axiosInstance
            .post(`/club${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/club${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const createOrUpdateClub = async (params) => clubApi.post('/createorupdateclub', params);

export const updateAvatar = async (params) => clubApi.post('/uploadavatar', params);

export const getAvatar = async (params) => clubApi.post('/getavatar', params);

export const removeAvatar = async (params) => clubApi.post('/removeavatar', params);

export const getClub = async (params) => clubApi.get('/getclub', { params });

export const saveClub = async (params) => clubApi.post('/save', params);

export const adminSaveClub = async (params) => clubApi.post('/adminsave', params);

export const getGeolocationClubs = async (params) => clubApi.get('/getgeolocationclubs', { params });

export const getClubsPublic = async (params) => clubApi.get('/getclubspublic', { params });
