import * as actionTypes from './actions';

export const initialState = {
    isDevicePaired: false
};

const signalrReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIGNALR_DEVICE_PAIRED:
            return {
                ...state,
                isDevicePaired: true
            };
        case actionTypes.SIGNALR_DEVICE_UNPAIRED:
            return {
                ...state,
                isDevicePaired: false
            };
        default:
            return state;
    }
};

export default signalrReducer;
