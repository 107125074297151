/* eslint-disable */

// Material & React
import { Stack, Typography, Tooltip, Box, IconButton, ButtonGroup, Button } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState } from 'react';

// Wijmo
import { Popup } from '@grapecity/wijmo.react.input';

// Project Imports
import FieldSearchGrid from './FieldSearchGrid';
import ValueListGrid from './ValueListGrid';
import './criteria.css';
import { useTheme } from '@emotion/react';

const Criteria = (props) => {
    const [tab, setTab] = useState(0);

    const theme = useTheme();

    const { popupInitialized, criteriaRef, submit, handleDirtyCheck, dirtyValuesList, dirtyFieldSearch } = props;

    const reset = () => {
        let dirty = false;
        criteriaRef?.current?.critFldsSearch?.forEach((crit) => {
            crit.value1 = null;
            crit.value2 = null;

            if (crit.defaultOperator === 'isNull' || crit.defaultOperator === 'isEmpty') {
                if (['date', 'datetime', 'int', 'decimal', 'boolean'].includes(crit.dataType)) crit.defaultOperator = '=';
                else crit.defaultOperator = 'contains';
            }
        });
        criteriaRef?.current?.critValuesList?.forEach((crit) => {
            crit.isSelected = false;
            crit?.children?.forEach((child, index) => {
                child.isSelected = false;
            });
        });
        props.gridRefs.current.forEach((gridRef) => gridRef.grid.control.refreshCells());
        handleDirtyCheck();
    };

    if (criteriaRef?.current)
        return (
            <Popup style={{ maxHeight: '96vh', overflowY: 'auto' }} initialized={popupInitialized} position={1}>
                <Stack style={{ maxHeight: '100%', overflowY: 'auto' }}>
                    <div className="wj-dialog-header Qub-DatagridBringValueView" style={{ backgroundColor: '#ebebeb' }}>
                        <div>
                            <div style={{ marginBottom: 0, marginTop: 0, display: 'flex', alignItems: 'center' }}>
                                <FilterAltIcon sx={{ marginRight: '3px', opacity: '0.5', width: '20px' }} />
                                <Typography fontWeight="light" sx={{ display: 'inline', opacity: '0.7' }} variant="body5">
                                    Κριτήρια /
                                </Typography>
                                &nbsp;
                                <Typography sx={{ display: 'inline' }} variant="body6">
                                    {tab === 0 && criteriaRef?.current?.critValuesList ? 'Λίστα Τιμών' : 'Πεδία Αναζήτησης'}
                                </Typography>
                            </div>
                        </div>
                        <button type="button" style={{ cursor: 'pointer' }} tabIndex={-1} className="close wj-hide">
                            &times;
                        </button>
                    </div>
                    <Stack sx={{ padding: 2 }}>
                        <div className={tab === 0 && criteriaRef?.current?.critValuesList ? 'hidden' : ''}>
                            <FieldSearchGrid
                                handleDirtyCheck={handleDirtyCheck}
                                gridRefs={props.gridRefs}
                                data={criteriaRef?.current?.critFldsSearch}
                                name="h1"
                            />
                        </div>
                        <div className={tab === 1 || !criteriaRef?.current?.critValuesList ? 'hidden' : ''}>
                            <ValueListGrid
                                handleDirtyCheck={handleDirtyCheck}
                                data={criteriaRef?.current?.critValuesList}
                                gridRefs={props.gridRefs}
                                name="h2"
                            />
                        </div>
                        <Box sx={{ width: '100%', display: 'flex', direction: 'row', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', direction: 'row', gap: 1 }}>
                                {criteriaRef?.current?.critValuesList && (
                                    <Box boxShadow={2} sx={{ borderRadius: 50 }}>
                                        <Tooltip title="Λίστα τιμών">
                                            <IconButton onClick={() => setTab(0)}>
                                                <ListIcon sx={{ color: dirtyValuesList ? 'red' : '' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                                <Box boxShadow={2} sx={{ borderRadius: 50 }}>
                                    <Tooltip title="Πεδία Αναζήτησης">
                                        <IconButton onClick={() => setTab(1)}>
                                            <TextFieldsIcon sx={{ color: dirtyFieldSearch ? 'red' : '' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                {/* <Box boxShadow={2} sx={{ borderRadius: 50 }}>
                                    <Tooltip title="Προβολή">
                                        <IconButton onClick={submit} className="close wj-hide">
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box> */}
                                <Box boxShadow={2} sx={{ borderRadius: 50 }}>
                                    <Tooltip title="Διαγραφή Φίλτρων">
                                        <IconButton color="error" onClick={reset}>
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>

                            <Box sx={{}}>
                                <ButtonGroup>
                                    <Button
                                        sx={{
                                            borderRadius: '24px 0px 0px 24px',
                                            background: theme.palette.primary.dark,
                                            color: 'white',
                                            width: 70,
                                            ':hover': { background: theme.palette.primary.main, color: '#fff' }
                                        }}
                                        variant="contained"
                                        secondary="red"
                                        onClick={submit}
                                    >
                                        OK
                                    </Button>
                                    <Button
                                        sx={{
                                            borderRadius: '0px 24px 24px 0px',
                                            background: theme.palette.error.main,
                                            color: 'white',
                                            width: 70,
                                            ':hover': { background: theme.palette.error.light, color: '#fff' }
                                        }}
                                        variant="contained"
                                        className="close wj-hide"
                                    >
                                        Cancel
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </Stack>
                </Stack>
            </Popup>
        );
    else return null;
};

export default Criteria;
