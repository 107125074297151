/*eslint-disable*/
// mui- imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import excel from 'assets/images/excelIcon.png';
import csv from 'assets/images/csvIcon.png';
import pdf from 'assets/images/pdf.png';
import { useEffect, useState, forwardRef, useImperativeHandle, useRef, useContext } from 'react';
import { ArrowBack, FilterAlt, ViewColumnTwoTone, Refresh, BarChart } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
// import { authorizedGenApiCall, authorizedReportApiCall } from "api/genApiCalls";
// import { apiDataKeyExplorerToolbarData } from "api/ApiDatakeys";
import { Stack } from '@mui/material';

import ColumnPicker from './components/ColumnPicker';
import { useForm } from 'react-hook-form';
import FilesMenu from './components/FilesMenu';
import { downloadFile, openPdf } from 'qubCommon/helperMethods/fileHelper';
import Criteria from './components/Criteria';
import { formatCritFldSearch, formatValuesListSearch } from 'qubCommon/helperMethods/formatRequest';
import { reports } from 'apis';
import { useMe } from 'hooks/useMe';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { LoaderContext } from 'context/loaderContext';
import { useLoading } from 'hooks/useLoading';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'qubCommon/Inputs';

const ToolbarExplorer = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { whoami } = useMe();
    /* Report Files Excel,CSV,PDF States */

    // Objects describing files : {id,title etc}
    const [menuFiles, setMenuFiles] = useState([]);
    // open,setOpen(anchorEl)
    const [filesMenu, setFilesMenu] = useState(null);
    // 3 Menus have the same Component so in order to open the correct one we have to set fileMenuType before openning
    const [fileMenuType, setFileMenuType] = useState(null);

    // Criteria Dirty Check
    const [dirtyValuesList, setDirtyValuesList] = useState(false);
    const [dirtyFieldSearch, setDirtyFieldSearch] = useState(false);

    // Redux variables

    // props
    const {
        excelHandleClick,
        webPageKey,
        title,
        gridRef,
        mobileGridRef,
        form,
        rptRequestVars,
        submit,
        toolbarProps,
        children,
        setToolbarLoading,
        hideCriteria = false
    } = props;
    // const { csvFiles, pdfFiles, excelFiles, columns, criteriaRef } = useToolbarProps({ webPageKey }); migrated to parent component
    const { csvFiles, pdfFiles, excelFiles, columns, criteriaRef, newTitle, newUrl, hideAddNew, showRowHeader, pageSize } = toolbarProps;

    // methods are used for ColumnPicker visibleFields
    const methods = useForm();

    // Criteria and ColumnPicker Popup Refs
    const colPopupRef = useRef();
    const criteriaPopupRef = useRef();

    // GridRefs is an array of refs used for Dynamic Criteria Component
    const gridRefs = useRef([]);

    const { setLoading } = useLoading();

    const handleDirtyCheck = (initRef) => {
        if (initRef) {
            let dirty = false;
            initRef?.current?.critFldsSearch?.forEach((crit) => {
                if (crit.value1 || crit.value2) {
                    dirty = 'fs';
                }
            });
            initRef?.current?.critValuesList?.forEach((crit) => {
                let dirtyChildren = 0;
                crit?.children?.forEach((child, index) => {
                    if (child?.isSelected) {
                        dirtyChildren += 1;
                    }
                    if (dirtyChildren && dirtyChildren !== crit?.children?.length && index === crit?.children?.length - 1)
                        if (dirty === 'fs' || dirty === 'fsvl') dirty = 'fsvl';
                        else dirty = 'vl';
                });
            });
            if (dirty === 'fsvl') {
                setDirtyFieldSearch(true);
                setDirtyValuesList(true);
            } else if (dirty === 'fs') {
                setDirtyFieldSearch(true);
                setDirtyValuesList(false);
            } else if (dirty === 'vl') {
                setDirtyFieldSearch(false);
                setDirtyValuesList(true);
            } else {
                setDirtyFieldSearch(false);
                setDirtyValuesList(false);
            }
        } else {
            let dirty = false;
            criteriaRef?.current?.critFldsSearch?.forEach((crit) => {
                if (crit.value1 || crit.value2) {
                    dirty = 'fs';
                }
            });
            criteriaRef?.current?.critValuesList?.forEach((crit) => {
                let dirtyChildren = 0;
                crit?.children?.forEach((child, index) => {
                    if (child?.isSelected) {
                        dirtyChildren += 1;
                    }
                    if (dirtyChildren && dirtyChildren !== crit?.children?.length && index === crit?.children?.length - 1)
                        if (dirty === 'fs' || dirty === 'fsvl') dirty = 'fsvl';
                        else dirty = 'vl';
                });
            });
            if (dirty === 'fsvl') {
                setDirtyFieldSearch(true);
                setDirtyValuesList(true);
            } else if (dirty === 'fs') {
                setDirtyFieldSearch(true);
                setDirtyValuesList(false);
            } else if (dirty === 'vl') {
                setDirtyFieldSearch(false);
                setDirtyValuesList(true);
            } else {
                setDirtyFieldSearch(false);
                setDirtyValuesList(false);
            }
        }
    };

    // !!! ΕΙΤ 2023.06.01 - Το κλεινω προσωρινα
    // set Tab Title
    useEffect(() => {
        // document.title = webPageKey;
        handleDirtyCheck(criteriaRef);
    }, [criteriaRef?.current?.critValuesList]);

    // populating methods based on fetched data
    useEffect(() => {
        if (columns?.length >= 1) {
            columns?.forEach((c) => {
                c?.children?.forEach((child) => {
                    methods.setValue(`${child.fldName}`, Boolean(child.isVisible));
                });
            });
        }
    }, [columns, methods]);

    // function used to map data to grid (binding,header,visible) for unbound grids
    const mapHeaders = (flex) => {
        flex.columns.forEach((c) => {
            c.visible = false;
        });
        flex.columns.forEach((c) => {
            c.wordWrap = true;

            columns.forEach((column) => {
                const res = column.children.find((item) => item.fldName === c.binding);
                if (res && methods.getValues(res?.fldName)) {
                    c.visible = true;
                    // flex.autoSizeColumn(c.index);
                }
                if (res?.fldTitle) {
                    c.header = res?.fldTitle;
                }
            });
        });
    };

    // imperative handler, our Ref comes from our Components Hook. Px. ParastatikaExplorer(Component), useParastatikaExplorer(MainHook): ref is created in useParastatikaExplorer
    useImperativeHandle(ref, () => ({
        mapHeaders,
        criteriaRef
    }));

    // columnPickerPopUP initialization
    const colPopupInitialized = (popup) => {
        popup.isDraggable = true;
        popup.isResizable = false;
        popup.fadeOut = false;
        popup.fadeIn = false;

        colPopupRef.current = popup;
    };

    // criteriaPopUP initialization
    const criteriaPopupInitialized = (popup) => {
        popup.isDraggable = true;
        popup.isResizable = true;
        popup.fadeOut = false;
        popup.fadeIn = false;
        criteriaPopupRef.current = popup;
    };

    // Pick File, this function will be probided to FilesMenu
    const handleFilePick = async (file) => {
        setToolbarLoading(true);
        const filterParams = form?.getValues();
        if (file?.rptId === 'default') {
            excelHandleClick();
            setLoading(false);
        } else if (fileMenuType === 'Pdf') {
            // let params;
            // const formValues = formatRequestObject(form?.getValues());
            // if (rptRequestVars && form?.getValues()) {
            //     params = [...rptRequestVars, ...formValues, { dataKey: 'rptId', dataValue: file?.rptId }];
            // } else if (rptRequestVars && !form?.getValues()) {
            //     params = [...rptRequestVars, { dataKey: 'rptId', dataValue: file?.rptId }];
            // } else if (!rptRequestVars && form.getValues()) {
            //     params = [...formValues, { dataKey: 'rptId', dataValue: file?.rptId }];
            // } else params = [{ dataKey: 'rptId', dataValue: file?.rptId }];

            filterParams.critFldsSearch = formatCritFldSearch(criteriaRef?.current?.critFldsSearch);
            filterParams.critValuesList = formatValuesListSearch(criteriaRef?.current?.critValuesList);

            try {
                const res = await reports.getQubReport(
                    {
                        ReportId: file?.rptId,
                        DBKey: window.CONFIG.dbKey,
                        UserId: whoami?.userId,
                        ReportParams: filterParams
                    },
                    {
                        responseType: 'blob'
                    }
                );
                openPdf(res);
            } catch {
                console.log('error on get QubReport');
            }
        } else if (fileMenuType === 'Excel') {
            try {
                const res = await reports.getQubReportExcel(
                    {
                        ReportId: file?.rptId,
                        DBKey: window.CONFIG.dbKey,
                        UserId: whoami?.userId,
                        ReportParams: filterParams
                    },
                    {
                        responseType: 'blob'
                    }
                );
                downloadFile(res);
            } catch {
                console.log('error on getQubReport');
            }
        } else if (fileMenuType === 'Csv') {
            try {
                const res = await reports.getQubReportExcel(
                    {
                        ReportId: file?.rptId,
                        DBKey: window.CONFIG.dbKey,
                        UserId: whoami?.userId,
                        ReportParams: filterParams
                    },
                    {
                        responseType: 'blob'
                    }
                );
                downloadFile(res);
            } catch {
                console.log('error on getQubReport');
            }
        }
        setToolbarLoading(false);
        setFilesMenu(null);
    };

    // Open Menu based on Icon (xls,csv,pdf)
    const handleMenuOpen = (event, fileType) => {
        switch (fileType) {
            case 'xls':
                if (excelFiles?.length > 0) {
                    setMenuFiles(excelFiles);
                    setFileMenuType('Excel');
                    setFilesMenu(event.currentTarget);
                }
                break;
            case 'csv':
                if (csvFiles?.length > 0) {
                    setMenuFiles(csvFiles);
                    setFileMenuType('Csv');
                    setFilesMenu(event.currentTarget);
                }
                break;
            case 'pdf':
                if (pdfFiles?.length > 0) {
                    setMenuFiles(pdfFiles);
                    setFileMenuType('Pdf');
                    setFilesMenu(event.currentTarget);
                }
                break;
            default:
                console.log('provide a valid file type');
        }
    };

    const handleFilterSubmit = () => {
        submit();
    };

    return (
        <>
            <FilesMenu
                anchorEl={filesMenu}
                setAnchorEl={setFilesMenu}
                files={menuFiles}
                fileMenuType={fileMenuType}
                handleFilePick={handleFilePick}
            />
            {columns?.length >= 1 && (
                <ColumnPicker
                    columns={columns}
                    popupInitialized={colPopupInitialized}
                    showRowHeader={showRowHeader}
                    gridRef={gridRef}
                    mobileGridRef={mobileGridRef}
                    methods={methods}
                    webPageKey={webPageKey}
                />
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                {!hideCriteria && (
                    <Criteria
                        popupInitialized={criteriaPopupInitialized}
                        methods={methods}
                        handleDirtyCheck={handleDirtyCheck}
                        dirtyFieldSearch={dirtyFieldSearch}
                        dirtyValuesList={dirtyValuesList}
                        gridRefs={gridRefs}
                        criteriaRef={criteriaRef}
                        submit={handleFilterSubmit}
                    />
                )}

                <Box my={1}>
                    <Stack direction="row" spacing={1}>
                        {!hideCriteria && (
                            <Box
                                boxShadow={2}
                                sx={{ borderRadius: 50, backgroundColor: dirtyFieldSearch || dirtyValuesList ? '#759FDD33' : ' ' }}
                            >
                                <Tooltip title="Φίλτρα" onClick={() => criteriaPopupRef.current.show(true)} sx={{}}>
                                    <IconButton>
                                        <FilterAlt />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        {columns?.length !== 0 && (
                            <Box boxShadow={2} sx={{ borderRadius: 50 }}>
                                <Tooltip title="Στήλες">
                                    <IconButton onClick={() => colPopupRef.current.show(true)}>
                                        <ViewColumnTwoTone />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        <Box boxShadow={2} sx={{ borderRadius: 50 }}>
                            <Tooltip title={t('ExportToExcel')}>
                                <IconButton onClick={(e) => handleMenuOpen(e, 'xls')}>
                                    <img
                                        src={excel}
                                        alt="excel"
                                        style={excelFiles?.length === 0 ? { opacity: 0.4, height: '24px' } : { height: '24px' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <span hidden={pdfFiles?.length === 0}>
                            <Box boxShadow={2} sx={{ borderRadius: 50 }}>
                                <Tooltip title="Export PDF">
                                    <IconButton onClick={(e) => handleMenuOpen(e, 'pdf')}>
                                        <img
                                            style={pdfFiles?.length === 0 ? { opacity: 0.4, height: '24px' } : { height: '24px' }}
                                            src={pdf}
                                            alt="excel"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </span>
                        {!hideAddNew && (
                            <Box boxShadow={2} sx={{ borderRadius: 50 }}>
                                <Tooltip title={newTitle} onClick={() => navigate(newUrl)}>
                                    <IconButton>
                                        <NoteAddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        {children}
                    </Stack>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {pageSize?.length > 0 && (
                        <Dropdown id="pageSize" name="pageSize" defaultValue={pageSize[0]?.value} options={pageSize} />
                    )}
                </Box>
                {/* <Box>
                    <Typography variant="h5">{title ?? webPageKey}</Typography>
                </Box> */}
            </Box>
        </>
    );
});

export default ToolbarExplorer;
