import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import excel from 'assets/images/excelIcon.png';
import csv from 'assets/images/csvIcon.png';
import pdf from 'assets/images/pdf.png';
import React from 'react';

const FilesMenu = ({ anchorEl, setAnchorEl, files, fileMenuType, handleFilePick }) => {
    const open = Boolean(anchorEl);

    const handleClose = (file) => {
        setAnchorEl(null);
    };

    const FileMenuIcon = () => {
        if (fileMenuType === 'Excel') return <img style={{ height: '24px' }} src={excel} alt="excel" />;
        if (fileMenuType === 'Csv') return <img style={{ height: '24px' }} src={csv} alt="excel" />;
        if (fileMenuType === 'Pdf') return <img style={{ height: '24px' }} src={pdf} alt="excel" />;
        return null;
    };

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
                'aria-labelledby': 'basic-button'
            }}
        >
            {files?.map((file) => {
                return (
                    <MenuItem key={file?.rptTitle} onClick={() => handleFilePick(file)}>
                        <ListItemIcon>
                            <FileMenuIcon />
                        </ListItemIcon>
                        {file?.rptTitle}
                    </MenuItem>
                );
            })}
        </Menu>
    );
};

export default FilesMenu;
