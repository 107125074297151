import PropTypes from 'prop-types';

// i18n
import { Trans } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

// permissions
import { Can } from 'context/permissionContext';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();
    // menu list collapse & items
    const [subject, action] = item.permission?.split('.') ?? [];

    const items = item.children?.map((menuItem) => {
        const [subject, action] = menuItem.permission?.split('.') ?? [];
        switch (menuItem.type) {
            case 'collapse':
                return (
                    <Can I={action} a={subject} key={menuItem.id} passThrough>
                        {(allowed) => (allowed || (!subject && !action)) && <NavCollapse key={menuItem.id} menu={menuItem} level={1} />}
                    </Can>
                );
            case 'item':
                return (
                    <Can I={action} a={subject} key={menuItem.id} passThrough>
                        {(allowed) => (allowed || (!subject && !action)) && <NavItem item={menuItem} level={1} />}
                    </Can>
                );

            default:
                return (
                    <Typography key={menuItem.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <Can I={action} a={subject} key={item.id} passThrough>
            {(allowed) =>
                allowed || (!subject && !action) ? (
                    <>
                        <List
                            subheader={
                                item.title && (
                                    <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                                        <Trans i18nKey={item.title}>{item.title}</Trans>
                                        {item.caption && (
                                            <Typography
                                                variant="caption"
                                                sx={{ ...theme.typography.subMenuCaption }}
                                                display="block"
                                                gutterBottom
                                            >
                                                {item.caption}
                                            </Typography>
                                        )}
                                    </Typography>
                                )
                            }
                        >
                            {items}
                        </List>

                        {/* group divider */}
                        <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                    </>
                ) : null
            }
        </Can>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
