import reportsAxiosInstance from 'apis/reportsAxiosInstance';

const qubiteqSubKey = window.CONFIG?.qubSubKey;

export const reportsApi = {
    post: (path, params, options, cancelToken) =>
        reportsAxiosInstance
            .post(`${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token,
                headers: {
                    'qubiteq-subscription-key': qubiteqSubKey,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        reportsAxiosInstance
            .get(`/event${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const getQubReport = async (params, options) => reportsApi.post('/Report/GetPDF', params, options);

export const getQubReportExcel = async (params, options) => reportsApi.post('/Report/GetExcel', params, options);

export const saveFile = async (params, options) => reportsApi.post('/File/UploadFile', params, options);

export const getFile = async (params, options) => reportsApi.post('/File/GetFile', params, options);
