import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Box, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { devices } from 'apis';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';

interface QrDialogProps {
    open: boolean;
    accessToken: string;
    onClose: () => void;
}

const QrDialog = (props: QrDialogProps) => {
    const { open, accessToken, onClose } = props;
    const { t } = useTranslation();
    const [refetch, setRefetch] = useState<boolean>(false);

    const qrTokenQuery = useQuery(['token', open, refetch], async () => {
        const qrToken = await devices.issueActivationToken({ accessToken });
        return qrToken;
    });

    const handleRefetch = () => {
        setRefetch((prev) => !prev);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                <Typography variant="body1">Scan to Pair</Typography>
            </DialogTitle>
            {
                {
                    loading: (
                        <DialogContent>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1, height: '256px' }}>
                                <CircularProgress size={70} />
                            </Box>
                        </DialogContent>
                    ),
                    success: (
                        <DialogContent>
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                                {qrTokenQuery?.data?.token && <QRCode size={256} value={qrTokenQuery.data.token} viewBox="0 0 256 256" />}
                            </Box>
                        </DialogContent>
                    ),
                    error: (
                        <DialogContent>
                            <p>An Error Occured</p>
                        </DialogContent>
                    ),
                    idle: null
                }[qrTokenQuery.status]
            }
            <DialogActions>
                <Button
                    variant="contained"
                    sx={{ background: '#4d79bc', ':hover': { background: '#79AEC9', color: '#fff' } }}
                    onClick={handleRefetch}
                >
                    {t('NewQR')}
                </Button>
                <Button
                    variant="contained"
                    sx={{ background: '#c42c0e', ':hover': { background: '#c9807d', color: '#fff' } }}
                    onClick={onClose}
                >
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QrDialog;
