import { FlexGrid} from '@grapecity/wijmo.react.grid';
import { Button } from '@mui/material';
import {admin } from 'apis';
import {useQuery } from 'react-query';
import { useRef, useState } from 'react';

const AppSettings = () => {
    const settingsGridRef = useRef();
    const appSettingsGridRef = useRef();
    const [settingsChanges, setSettingsChanges] = useState({});
    const [appSettingsChanges, setAppSettingsChanges] = useState({});
    const [originalSettings, setOriginalSettings] = useState({});
    const [originalAppSettings, setOriginalAppSettings] = useState({});
    const [isSettingsChanged, setIsSettingsChanged] = useState(false);
    const [isAppSettingsChanged, setIsAppSettingsChanged] = useState(false);

    const dataQuery = useQuery(['appSettings'], async () => {
        const result = await admin.getAppSettings();
        return result;
    });

    const handleSaveSettings = () => {
        //const settings = settingsGridRef.current.collectionView?.itemsEdited.map((i) => i).filter((obj) => Object.values(obj).some((val) => val !== null));
        const settings = Object.values(settingsChanges);
        admin.postSettings(settings);
        setSettingsChanges({});
        setOriginalSettings(prevOriginals => ({...prevOriginals, ...settingsChanges}));
        setIsSettingsChanged(false);
        console.log('settings', settings);
    };

    const handleSaveAppSettings = () => {
        //const appSettings = appSettingsGridRef.current.collectionView?.itemsEdited.map((i) => i).filter((obj) => Object.values(obj).some((val) => val !== null));
        const appSettings = Object.values(appSettingsChanges);
        admin.postAppSettings(appSettings);
        setAppSettingsChanges({});
        setOriginalAppSettings(prevOriginals => ({...prevOriginals, ...appSettingsChanges}));
        setIsAppSettingsChanged(false);
        console.log('appSettings', appSettings);
    };

    const trackChanges = (gridRef, setChanges, setChanged, originalData) => {
        const grid = gridRef.current;

        grid.cellEditEnding.addHandler((s, e) => {
            const editedItem = s.rows[e.row].dataItem;
            const editedField = s.columns[e.col].binding;
            const editedValue = s.activeEditor.value;

            const originalValue = originalData[editedItem.id]?.[editedField];  
            
            if (editedValue !== originalValue || editemItem[editedField] !== originalValue) {
                    setChanges((prevChanges) => {
                    const updatedChanges = { ...prevChanges };
                    updatedChanges[editedItem.id] = {
                        ...editedItem,
                        [editedField]: editedValue,
                    };
                    return updatedChanges;
                });
                setChanged(true);
            }
        });
    }

    const initializeSettings = (flex) => {
        settingsGridRef.current = flex;
        flex.collectionView.trackChanges = true;
        const originalData = {};
        flex.collectionView.itemsEdited.forEach((item) => {
            originalData[item.id] = { ...item};
        });
        setOriginalSettings(originalData);

        trackChanges(settingsGridRef, setSettingsChanges, setIsSettingsChanged, originalData);
    };

    const initializeAppSettings = (flex) => {
        appSettingsGridRef.current = flex;
        flex.collectionView.trackChanges = true;
         const originalData = {};
        flex.collectionView.itemsEdited.forEach((item) => {
            originalData[item.id] = { ...item};
        });
        setOriginalAppSettings(originalData);
        trackChanges(appSettingsGridRef, setAppSettingsChanges, setIsAppSettingsChanged, originalData);
    };


    return {
        loading: <p>loading</p>,
        success: (
            <>
            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                <h1>Settings</h1>
                <FlexGrid
                    initialized={(flex) => initializeSettings(flex)}
                    className="explorer-grid"
                    itemsSource={dataQuery?.data?.settings}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                />
                {isSettingsChanged && (
                    <Button variant="contained" color="primary" onClick={handleSaveSettings}>Save</Button>
                )}
            </div>    
            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                <h1>App Settings</h1>
                <FlexGrid
                    initialized={(flex) => initializeAppSettings(flex)}
                    className="explorer-grid"
                    itemsSource={dataQuery?.data?.appSettings}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                />
                {isAppSettingsChanged &&(
                    <Button variant="contained" color="primary" onClick={handleSaveAppSettings}>Save App Settings</Button>
                )}
            </div>
            </>
        ),
        error: <p>error</p>
    }[dataQuery.status];
}

export default AppSettings;