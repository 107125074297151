import axiosInstance from '../axiosInstance';

export const personApi = {
    post: (path, params, options, cancelToken) =>
        axiosInstance
            .post(`/person${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/person${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};
export const updateAvatar = async (params) => personApi.post('/uploadavatar', params);

export const getAvatar = async (params) => personApi.post('/getavatar', params);

export const getFiles = async (params) => personApi.post('/getfiles', params);

export const downloadFile = async (params, options) => personApi.post('/downloadfile', params, options);

export const removeAvatar = async (params) => personApi.post('/removeavatar', params);

export const getPerson = async (params) => personApi.get('/getperson', { params });

export const getPersonFromUser = async (params) => personApi.get('/getpersonfromuser', { params });

export const getRecommendedRoles = async (params) => personApi.get('/getrecommendedroles', { params });

export const inviteUser = async (params) => personApi.post('/invite', params);

export const reinvite = async (params) => personApi.post('/reinvite', params);

export const activateUserFromInvitation = async (params) => personApi.post('/activateUserFromInvitation', params);

export const createTempUser = async (params) => personApi.post('/createtempuser', params);

export const createOrUpdateFunctions = async (params) => personApi.post('/createorupdatefunctions', params);

export const deleteFunction = async (params) => personApi.post('/deletefunction', params);

export const validateB4Submit = async (params) => personApi.post('/validateb4submit', params);

export const submitRecord = async (params) => personApi.post('/SubmitPersonRecord', params);

export const approveRecord = async (params) => personApi.post('/Approveforverperson', params);

export const rejectRecord = async (params) => personApi.post('/RejectPerson', params);

export const removeFile = async (params) => personApi.post('/removefile', params);

export const getUserClub = async (params) => personApi.get('/getUserClub');

export const getFunctions = async (params) => personApi.get('/getpersonfunctions', { params });

export const getPersonByFunctionId = async (params) => personApi.get('/getPersonByFunctionId', { params });

export const savePerson = async (params) => personApi.post('/save', params);

export const adminSavePerson = async (params) => personApi.post('/adminSave', params);

export const getUserPersonFunction = async (params) => personApi.post('/GetUserPersonFunction', params);

export const updateUserPersonFunction = async (params) => personApi.post('/UpdateUserPersonFunction', params);
