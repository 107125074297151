import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const Logo = ({ dimensions, logo, link, styles }) => {
    const navigate = useNavigate();
    return (
        <Box
            onClick={() => {
                if (link) {
                    navigate(link);
                }
            }}
            sx={{
                width: dimensions.width,
                height: dimensions.height,
                backgroundImage: `url(${logo})`,
                backgroundSize: 'cover',
                cursor: link ? 'pointer' : 'default',
                ...styles
            }}
        />
    );
};

Logo.propTypes = {
    dimensions: PropTypes.object,
    logo: PropTypes.string,
    link: PropTypes.string,
    styles: PropTypes.object
};

Logo.defaultProps = {
    dimensions: { width: 200, height: 200 }
};

export default Logo;
