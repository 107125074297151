import { Box, Grid, IconButton, Stack } from '@mui/material';
import { useState, Suspense } from 'react';
import Map from '../Components/Map';
import { useTranslation } from 'react-i18next';
import { CardWrapper } from 'layout/PublicLayout/Wrappers';
import { ArrowBack } from '@mui/icons-material';
import ClubsGrid from '../Components/ClubsGrid';
import { useQuery } from 'react-query';
import { club } from 'apis';

interface ClubPublic {
    id: string;
    geolocationId?: number;
    code?: number;
    title?: string;
    abbreviation?: string;
    founded?: string;
    url?: string;
    email?: string;
}

const View = () => {
    const [selectedRegion, setSelectedRegion] = useState<number | null>(null);
    const { t } = useTranslation();

    const handleOnSelectRegion = (id: number) => {
        setSelectedRegion(id);
    };

    const clubsQuery = useQuery(
        'clubs',
        async () => {
            var res = await club.getClubsPublic();
            return res;
        },
        { suspense: true }
    );

    if (!selectedRegion)
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <CardWrapper>
                    <Map onSelectRegion={handleOnSelectRegion} />
                </CardWrapper>
            </Suspense>
        );
    return (
        <Stack spacing={2} sx={{ width: '100%', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', width: '80%', borderRadius: 2, background: '#F8F8F8', boxShadow: 1, px: 5, py: 1 }}>
                <IconButton onClick={() => setSelectedRegion(null)}>
                    <ArrowBack sx={{ width: 18 }} />
                </IconButton>
            </Box>
            <CardWrapper>
                <ClubsGrid data={clubsQuery?.data?.filter((c: ClubPublic) => c.geolocationId === selectedRegion)} />
            </CardWrapper>
        </Stack>
    );
};

export default View;
