// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    language: config.language,
    capitalizeInputFields: true,
    opened: true,
    theme: 'light'
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            };
        case actionTypes.SET_CAPITALIZE_INPUT_FIELDS:
            return {
                ...state,
                capitalizeInputFields: action.capitalizeInputFields
            };
        case actionTypes.SET_THEME:
            return {
                ...state,
                theme: action.theme
            };
        default:
            return state;
    }
};

export default customizationReducer;
