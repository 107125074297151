import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
// import XIcon from '@mui/icons-material/X';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Header, PublicFooter } from './components';

const Main = styled('div')(({ theme }) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    minHeight: '10vh',
    marginLeft: 0
}));

const PublicLayout = () => {
    const theme = useTheme();
    const mainRef = useRef<HTMLDivElement>(null);
    const xlUp = useMediaQuery(theme.breakpoints.up('xl'));
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
    const [prevScrollPos, setPrevScrollPos] = useState<number>(0);

    useEffect(() => {
        const mainElement = mainRef.current;

        const handleScroll = () => {
            if (mainRef.current && mainRef.current.scrollHeight < window.innerHeight) {
                setDrawerOpen(true);
                return;
            }
            const currentScroll = mainRef.current?.scrollTop || 0;
            if (prevScrollPos < currentScroll && drawerOpen) {
                setDrawerOpen(false);
            } else if (prevScrollPos > currentScroll && !drawerOpen) {
                setDrawerOpen(true);
            }
            setPrevScrollPos(currentScroll);
        };

        if (mainElement) {
            mainElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (mainElement) {
                mainElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [prevScrollPos, drawerOpen]);

    const mainMarginTop = useMemo(() => {
        if (lgUp) {
            console.log('lgUp', lgUp);
            return drawerOpen ? '25vh' : '0vh';
        }
        console.log('lgdown', lgUp);
        return drawerOpen ? '28vh' : '0vh';
    }, [drawerOpen, lgUp]);

    const mainHeight = useMemo(() => {
        if (lgUp) {
            return `calc(100vh - ${drawerOpen ? '20vh' : '3vh'})`;
        }
        return `calc(100vh - ${drawerOpen ? '28vh' : '3vh'})`;
    }, [drawerOpen, lgUp]);

    return (
        <Box sx={{ height: '100vh', overflow: 'hidden', pb: 2 }}>
            <CssBaseline />
            <Header open={drawerOpen} />
            <Main
                ref={mainRef}
                sx={{
                    background: theme.palette.background.default,
                    mt: mainMarginTop,
                    boxSizing: 'content-box',
                    overflowY: 'scroll',
                    height: mainHeight,
                    display: 'flex',
                    flexDirection: 'column',
                    width: lgUp ? '100%' : '94%'
                }}
                theme={theme}
            >
                <Outlet />
                <PublicFooter desktop={lgUp} />
            </Main>
        </Box>
    );
};

export default PublicLayout;
