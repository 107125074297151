function openPdf(res) {
    const blob = new Blob([res], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    window.open(link.href);
}

function downloadFile(res) {
    // const blob = new Blob([res]);

    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.setAttribute('download', `Report.xlsx`);
    // link.click();
    const url = URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.xlsx');
    document.body.appendChild(link);
    link.click();
}

export { openPdf, downloadFile };
