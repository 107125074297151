import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'qubCommon/Other/Loadable';

// permission
import { withPermission } from 'hoc';

// react-router
import { Navigate } from 'react-router-dom';
import Search from 'views/dashboard/Search';
import { ClubLayout, AthletesLayout } from 'ui-component/layouts';
import MinimalLayout from 'layout/MinimalLayout';
import CommonIndex from 'common/CommonIndex';
import ExplorerCard from 'ui-component/cards/ExplorerCard';
import PageUnderConstruction from 'views/PageUnderConstruction';
import { AppSettings } from 'views/admin/AppSettings';
import { ChecklistScreen } from 'views/admin/Checklist';
import { EventCategories } from 'views/admin/EventCategories';

// import ApplicationsView from 'views/application/Applications/index';
// import MainCard from 'ui-component/cards/MainCard';

// dashboard routing

const PaymentSubmittion = Loadable(lazy(() => import('views/application/Payments/Submittion')));
const PaymentResultPage = Loadable(lazy(() => import('views/application/Payments/Submittion/paymentMethod/byCard/form/resultPage')));

const HomeView = withPermission(Loadable(lazy(() => import('views/dashboard/Home'))), {
    unrestricted: true
});
const AccountSettings = withPermission(Loadable(lazy(() => import('views/settings/AccountSettings'))), {
    unrestricted: true
});
const ApplicationsView = withPermission(Loadable(lazy(() => import('views/application/Applications'))), {
    unrestricted: true
});
const ToolsView = withPermission(Loadable(lazy(() => import('views/application/Tools'))), {
    unrestricted: true
});
const PaymentsView = withPermission(
    Loadable(lazy(() => import('views/application/Transactions').then((module) => ({ default: module.TransactionOptions })))),
    {
        unrestricted: true
    }
);

const UsersView = withPermission(Loadable(lazy(() => import('views/dashboard/Users').then((module) => ({ default: module.View })))), {
    action: 'View',
    subject: 'Users'
});

/* const NewUsersView = withPermission(Loadable(lazy(() => import('views/dashboard/Users').then((module) => ({ default: module.View })))), {
    action: 'View',
    subject: 'Users'
}); */

const UsersManage = withPermission(Loadable(lazy(() => import('views/dashboard/Users').then((module) => ({ default: module.Manage })))), {
    action: 'Manage',
    subject: 'Users'
});
const UsersCreate = withPermission(Loadable(lazy(() => import('views/dashboard/Users').then((module) => ({ default: module.Create })))), {
    action: 'Create',
    subject: 'Users'
});
const RolesView = withPermission(Loadable(lazy(() => import('views/dashboard/Roles').then((module) => ({ default: module.View })))), {
    action: 'View',
    subject: 'Roles'
});
const RolesManage = withPermission(Loadable(lazy(() => import('views/dashboard/Roles').then((module) => ({ default: module.Manage })))), {
    action: 'Manage',
    subject: 'Roles'
});
const ClubsView = withPermission(Loadable(lazy(() => import('views/application/Clubs').then((module) => ({ default: module.View })))), {
    action: 'View',
    subject: 'Clubs'
});

const ClubManage = withPermission(Loadable(lazy(() => import('views/application/Clubs').then((module) => ({ default: module.Manage })))), {
    action: 'Manage',
    subject: 'Clubs'
});
const AthletesView = withPermission(
    Loadable(lazy(() => import('views/application/Athletes').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'Athletes'
    }
);

const AthletesManage = withPermission(
    Loadable(lazy(() => import('views/application/Athletes').then((module) => ({ default: module.Manage })))),
    {
        action: 'Manage',
        subject: 'Athlete'
    }
);

const OfficialsView = withPermission(
    Loadable(lazy(() => import('views/application/Officials').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'Athletes'
    }
);

const OfficialsManage = withPermission(
    Loadable(lazy(() => import('views/application/Officials').then((module) => ({ default: module.Manage })))),
    {
        action: 'Manage',
        subject: 'Official'
    }
);

const PaymentIndexView = withPermission(
    Loadable(lazy(() => import('views/application/Transactions').then((module) => ({ default: module.View })))),
    {
        action: 'Explore',
        subject: 'Index'
    }
);

const PaymentIndexManage = withPermission(
    Loadable(lazy(() => import('views/application/Transactions').then((module) => ({ default: module.Manage })))),
    {
        action: 'Manage',
        subject: 'Index'
    }
);

const PaymentCardView = withPermission(
    Loadable(lazy(() => import('views/application/Transactions').then((module) => ({ default: module.Manage })))),
    {
        action: 'Manage',
        subject: 'Transaction'
    }
);

const PaymentPendingView = withPermission(
    Loadable(lazy(() => import('views/application/Transactions').then((module) => ({ default: module.View })))),
    {
        action: 'Explore',
        subject: 'Pending'
    }
);

// //////////////////////////////////////////// Applications
const ApplicationsAthletesView = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Athletes').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'Athletes'
    }
);

const ApplicationsAthletesManage = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Athletes').then((module) => ({ default: module.Manage })))),
    {
        action: 'Create',
        subject: 'Athlete'
    }
);

const ApplicationsAthletesCertificates = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Athletes').then((module) => ({ default: module.Certificates })))),
    {
        action: 'Create',
        subject: 'Athlete'
    }
);

const ApplicationsAthletesBatchRenewal = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Athletes').then((module) => ({ default: module.BatchRenewal })))),
    {
        action: 'Create',
        subject: 'Athlete'
    }
);

const ApplicationsAthletesEtepsAndRenewal = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Athletes').then((module) => ({ default: module.BatchEtepsAndRenewal })))),
    {
        action: 'Create',
        subject: 'Athlete'
    }
);

const ApplicationAthletesBatchPractice = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Athletes').then((module) => ({ default: module.BatchAthletePractice })))),
    {
        action: 'Create',
        subject: 'Athlete'
    }
);

const ApplicationsAthletesSelfInsurance = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Athletes').then((module) => ({ default: module.BatchSelfInsurance })))),
    {
        action: 'Create',
        subject: 'Athlete'
    }
);

const ApplicationsAthletesFedInsurance = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Athletes').then((module) => ({ default: module.BatchFedInsurance })))),
    {
        action: 'Create',
        subject: 'Athlete'
    }
);

const ApplicationsOfficialsBatchRenewal = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Officials').then((module) => ({ default: module.Batch })))),
    {
        action: 'Create',
        subject: 'Athlete'
    }
);

const ApplicationsOfficialsCertificates = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Officials').then((module) => ({ default: module.Certificates })))),
    {
        action: 'Manage',
        subject: 'Official'
    }
);

const ApplicationsClubsCertificates = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Clubs').then((module) => ({ default: module.Certificates })))),
    {
        action: 'Create',
        subject: 'Clubs'
    }
);

const EmailToolView = withPermission(
    Loadable(lazy(() => import('views/application/Tools/EmailTool').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'EmailTool'
    }
);

const EmailToolCreate = withPermission(
    Loadable(lazy(() => import('views/application/Tools/EmailTool').then((module) => ({ default: module.Manage })))),
    {
        action: 'View',
        subject: 'EmailTool'
    }
);

const MergesView = withPermission(
    Loadable(lazy(() => import('views/application/Tools/MergeTool').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'MergeTool'
    }
);

const MergeManage = withPermission(
    Loadable(lazy(() => import('views/application/Tools/MergeTool').then((module) => ({ default: module.Manage })))),
    {
        action: 'View',
        subject: 'MergeTool'
    }
);

const NewMerge = withPermission(
    Loadable(lazy(() => import('views/application/Tools/MergeTool').then((module) => ({ default: module.New })))),
    {
        action: 'View',
        subject: 'MergeTool'
    }
);

const ApplicationsClubsView = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Clubs').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'Athletes'
    }
);

const ApplicationsClubsManage = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Clubs').then((module) => ({ default: module.Manage })))),
    {
        action: 'Manage',
        subject: 'Clubs'
    }
);

const ApplicationsOfficialsView = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Officials').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'Athletes'
    }
);

const ApplicationsOfficialsManage = withPermission(
    Loadable(lazy(() => import('views/application/Applications/Officials').then((module) => ({ default: module.Manage })))),
    {
        action: 'Create',
        subject: 'Athlete'
    }
);

const NewApplicationView = withPermission(Loadable(lazy(() => import('views/application/Applications/New/Index'))), {
    action: 'View',
    subject: 'Athletes'
});

const Competitions = withPermission(Loadable(lazy(() => import('views/application/Competitions'))), {
    unrestricted: true
});

const CompetitionsDetailsView = withPermission(
    Loadable(lazy(() => import('views/application/Competitions/Details').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const CompetitionDetailsManage = Loadable(
    Loadable(lazy(() => import('views/application/Competitions/Details').then((module) => ({ default: module.Manage })))),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const CompetitionParticipationsView = withPermission(
    Loadable(lazy(() => import('views/application/Competitions/Participations').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const CompetitionParticipationsIntentionToEnterView = withPermission(
    Loadable(
        lazy(() => import('views/application/Competitions/ParticipationsIntentionToEnter').then((module) => ({ default: module.View })))
    ),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const CompetitionsParticipationsIntentionToEnterManage = withPermission(
    Loadable(
        lazy(() => import('views/application/Competitions/ParticipationsIntentionToEnter').then((module) => ({ default: module.Manage })))
    ),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const ParticipationsExplorer = withPermission(
    Loadable(lazy(() => import('views/application/Competitions/Participations').then((module) => ({ default: module.Participations })))),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const ParticipationsIntentionToEnterExplorer = withPermission(
    Loadable(
        lazy(() =>
            import('views/application/Competitions/ParticipationsIntentionToEnter').then((module) => ({ default: module.Participations }))
        )
    ),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const CompetitionsParticipationsManage = withPermission(
    Loadable(lazy(() => import('views/application/Competitions/Participations').then((module) => ({ default: module.Manage })))),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const ParticipationsListCompetitions = withPermission(
    Loadable(
        lazy(() => import('views/application/Competitions/ParticipationList').then((module) => ({ default: module.CompetitionsView })))
    ),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const ParticipationsListParticipations = withPermission(
    Loadable(
        lazy(() => import('views/application/Competitions/ParticipationList').then((module) => ({ default: module.ParticipationsView })))
    ),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const SitesView = withPermission(Loadable(lazy(() => import('views/application/Sites').then((module) => ({ default: module.View })))), {
    action: 'Explore',
    subject: 'Site'
});

const SitesManage = withPermission(Loadable(lazy(() => import('views/application/Sites').then((module) => ({ default: module.Manage })))), {
    action: 'Manage',
    subject: 'Site'
});

const FaqView = withPermission(Loadable(lazy(() => import('views/application/Faq').then((module) => ({ default: module.View })))), {
    action: 'View',
    subject: 'FAQ'
});

const FaqManage = withPermission(Loadable(lazy(() => import('views/application/Faq').then((module) => ({ default: module.Manage })))), {
    action: 'Manage',
    subject: 'FAQ'
});

const FaqEdit = withPermission(Loadable(lazy(() => import('views/application/Faq').then((module) => ({ default: module.Edit })))), {
    action: 'Update',
    subject: 'FAQ'
});
// /////////////////////////////////////////////////////////////////////////////////////////////

// admin routing
const ImpersonationView = withPermission(Loadable(lazy(() => import('views/admin/Impersonation'))), {
    action: 'Impersonation',
    subject: 'Admin'
});
const GridManage = withPermission(Loadable(lazy(() => import('views/admin/ConfigTables').then((module) => ({ default: module.Manage })))), {
    action: 'Manage',
    subject: 'ConfigTables'
});
const GridView = withPermission(Loadable(lazy(() => import('views/admin/ConfigTables').then((module) => ({ default: module.View })))), {
    action: 'View',
    subject: 'ConfigTables'
});

const FormsView = withPermission(Loadable(lazy(() => import('views/admin/Forms').then((module) => ({ default: module.View })))), {
    action: 'Manage',
    subject: 'Forms'
});

const FormsManage = withPermission(Loadable(lazy(() => import('views/admin/Forms').then((module) => ({ default: module.Manage })))), {
    action: 'Update',
    subject: 'Forms'
});

const FormsPreview = withPermission(Loadable(lazy(() => import('views/admin/Forms').then((module) => ({ default: module.Preview })))), {
    action: 'Manage',
    subject: 'Forms'
});

const ResultsJsonForm = withPermission(Loadable(lazy(() => import('views/admin/ConfigTables/components/resultsJsonForm'))), {
    action: 'Manage',
    subject: 'ConfigTables'
});

const PageNotFound = Loadable(lazy(() => import('views/PageNotFound')));
const ServerError = Loadable(lazy(() => import('views/ServerError')));
const GDPR = Loadable(lazy(() => import('views/GDPR')));
const TermsOfUse = Loadable(lazy(() => import('views/TermsOfUse')));

// const PageUnderConstruction = Loadable(lazy(() => import('views/PageUnderConstruction')));

// user manage
const UserDetails = Loadable(lazy(() => import('views/dashboard/Users/Manage/Details')));
const UserRoles = Loadable(lazy(() => import('views/dashboard/Users/Manage/Roles')));
const UserPermissions = Loadable(lazy(() => import('views/dashboard/Users/Manage/Permissions')));

// role manage
const RolesDetails = Loadable(lazy(() => import('views/dashboard/Roles/Manage/Details')));
const RolesPermissions = Loadable(lazy(() => import('views/dashboard/Roles/Manage/Permissions')));
// ==============================|| MAIN ROUTING ||============================== //
const AthleteDetails = Loadable(lazy(() => import('views/application/Athletes/Manage/Details')));
const AthleteFunctions = Loadable(lazy(() => import('views/application/Athletes/Manage/Functions')));
const AthleteNfcCard = Loadable(lazy(() => import('views/application/Athletes/Manage/NfcCard')));

const ClubDetails = Loadable(lazy(() => import('views/application/Clubs/Manage/Details')));

const OfficialDetails = Loadable(lazy(() => import('views/application/Officials/Manage/Details')));
const OfficialFunctions = Loadable(lazy(() => import('views/application/Officials/Manage/Functions')));

const TransactionHeaders = Loadable(lazy(() => import('views/application/Transactions/View/TransactionHeaders')));
const TransactionDetails = Loadable(lazy(() => import('views/application/Transactions/View/TransactionDetails')));

const StatisticsView = withPermission(Loadable(lazy(() => import('views/dashboard/Statistics'))), {
    action: 'View',
    subject: 'Statistics'
});

const CompetitionDetailsResultsView = withPermission(
    Loadable(lazy(() => import('views/application/Competitions/CompetitionDetailsResults').then((module) => ({ default: module.View })))),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const CompetitionDetailsResultsManage = withPermission(
    Loadable(lazy(() => import('views/application/Competitions/CompetitionDetailsResults').then((module) => ({ default: module.Manage })))),
    {
        action: 'View',
        subject: 'Competitions'
    }
);

const MainRoutes = () => [
    {
        path: '',
        element: <MainLayout />,
        children: [
            {
                path: 'home',
                element: <HomeView />
            },

            {
                path: 'search',
                element: <Search />
            },
            {
                path: 'user/settings',
                element: <AccountSettings />
            },
            {
                path: '',
                element: <Navigate to="/home" />
            },
            {
                path: 'site-is-temporarily-unavailable',
                element: <ServerError />
            },
            {
                path: 'GDPR',
                element: <GDPR />
            },
            {
                path: 'TermsOfUse',
                element: <TermsOfUse />
            },
            {
                path: '/invite',
                element: (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: 22 }}>
                        Παρακαλώ αποσυνδεθείτε για να αποδεχθείτε την πρόσκληση!
                    </div>
                )
            },
            {
                path: 'configtables',
                element: <GridView />
            },
            {
                path: 'configtables/manage/:dataKey',
                element: <GridManage />
            },
            {
                path: 'configtables/manage/settings',
                element: <AppSettings />
            },
            {
                path: 'configtables/manage/checklist',
                element: <ChecklistScreen />
            },
            {
                path: 'configtables/manage/eventCategories',
                element: <EventCategories />
            },
            {
                path: 'configtables/manage/results-config',
                element: <ResultsJsonForm />
            },
            {
                path: 'forms',
                element: <FormsView />
            },
            {
                path: 'forms/manage/:id',
                element: <FormsManage />
            },
            {
                path: 'forms/preview',
                element: <FormsPreview />
            },
            {
                path: '*',
                element: <PageNotFound />
            },
            {
                path: 'lookups',
                element: <PageUnderConstruction />
            }
        ]
    },
    {
        path: '',
        element: <MinimalLayout />,
        children: [
            {
                path: 'payment/:token',
                element: <PaymentSubmittion />
            },
            {
                path: 'api/payment/success',
                element: <PaymentResultPage />
            },
            {
                path: 'api/payment/fail',
                element: <PaymentResultPage />
            }
        ]
    },
    {
        path: 'users-and-access',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <CommonIndex />
            },
            {
                path: 'users',
                children: [
                    {
                        path: '',
                        element: <UsersView />
                    },
                    {
                        path: 'create',
                        element: <UsersCreate />
                    },
                    {
                        path: 'create/:existingPersonId',
                        element: <UsersCreate />
                    },
                    {
                        path: 'create/:existingPersonId/:personFunctionId',
                        element: <UsersCreate />
                    },
                    {
                        path: 'manage/:id',
                        element: <UsersManage />,
                        children: [
                            {
                                path: 'details',
                                element: <UserDetails />
                            },
                            {
                                path: 'roles',
                                element: <UserRoles />
                            },
                            {
                                path: 'permissions',
                                element: <UserPermissions />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'roles',
                children: [
                    {
                        path: '',
                        element: <RolesView />
                    },
                    {
                        path: 'manage/:id',
                        element: <RolesManage />,
                        children: [
                            {
                                path: 'details',
                                element: <RolesDetails />
                            },
                            {
                                path: 'permissions',
                                element: <RolesPermissions />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'impersonation',
                element: <ImpersonationView />
            }
        ]
    },
    {
        path: 'faq-manage',
        element: <MainLayout />,
        children: [{ path: '', element: <FaqManage /> }]
    },
    {
        path: 'clubs',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <ClubsView />
            },
            {
                path: 'manage/:id',
                element: <ClubManage />,
                children: [
                    {
                        path: 'details',
                        element: <ClubDetails />
                    }
                ]
            }
        ]
    },
    {
        path: 'athletes',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <AthletesView />
            },
            {
                path: 'manage/:id',
                element: <AthletesManage />,
                children: [
                    {
                        path: 'details',
                        element: <AthleteDetails />
                    },
                    {
                        path: 'functions',
                        element: <AthleteFunctions />
                    },
                    {
                        path: 'nfccard',
                        element: <AthleteNfcCard />
                    }
                ]
            }
        ]
    },
    {
        path: 'officials',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <OfficialsView />
            },
            {
                path: 'manage/:id',
                element: <OfficialsManage />,
                children: [
                    {
                        path: 'details',
                        element: <OfficialDetails />
                    },
                    {
                        path: 'functions',
                        element: <OfficialFunctions />
                    }
                ]
            }
        ]
    },
    {
        path: 'statistics',
        element: <MainLayout />,
        children: [
            { path: '', element: <CommonIndex /> },

            { path: 'clubs', element: <PageUnderConstruction /> },
            { path: 'athletes', element: <PageUnderConstruction /> },
            { path: 'officials', element: <PageUnderConstruction /> },
            { path: 'competitions', element: <PageUnderConstruction /> }
        ]
    },
    {
        path: 'applications',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <CommonIndex />
            },
            {
                path: 'clubs',
                element: <ApplicationsClubsView />
            },
            {
                path: 'clubs/manage/:id',
                element: <ApplicationsClubsManage />
            },
            {
                path: 'athletes',
                element: <ApplicationsAthletesView />
            },
            {
                path: 'athletes/manage/:id',
                element: <ApplicationsAthletesManage />
            },
            {
                path: 'athletes/certificate/:id',
                element: <ApplicationsAthletesCertificates />
            },
            {
                path: 'athletes/batch/:id',
                element: <ApplicationsAthletesBatchRenewal />
            },
            {
                path: 'athletes/etepsandrenewal/:id',
                element: <ApplicationsAthletesEtepsAndRenewal />
            },
            {
                path: 'athletes/selfinsurance/:id',
                element: <ApplicationsAthletesSelfInsurance />
            },
            {
                path: 'athletes/fedinsurance/:id',
                element: <ApplicationsAthletesFedInsurance />
            },
            {
                path: 'officials/certificate/:id',
                element: <ApplicationsOfficialsCertificates />
            },
            {
                path: 'officials/batch/:id',
                element: <ApplicationsOfficialsBatchRenewal />
            },
            {
                path: 'athletes/batchpractice/:id',
                element: <ApplicationAthletesBatchPractice />
            },
            {
                path: 'clubs/certificate/:id',
                element: <ApplicationsClubsCertificates />
            },
            {
                path: 'officials',
                element: <ApplicationsOfficialsView />
            },
            {
                path: 'officials/manage/:id',
                element: <ApplicationsOfficialsManage />
            },
            {
                path: 'new/:id',
                element: <NewApplicationView />
            }
        ]
    },
    {
        path: 'tools',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <CommonIndex />
            },
            {
                path: 'email',
                element: <EmailToolView />
            },
            {
                path: 'email/new',
                element: <EmailToolCreate />
            },
            {
                path: 'merge',
                element: <MergesView />
            },
            { path: 'merge/new', element: <NewMerge /> },
            { path: 'merge/manage/:id/details', element: <MergeManage /> }
        ]
    },
    {
        path: 'payments',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <CommonIndex />,
                children: []
            },
            {
                path: 'headers',
                element: (
                    <ExplorerCard>
                        <TransactionHeaders />
                    </ExplorerCard>
                )
            },
            {
                path: 'details',
                element: (
                    <ExplorerCard>
                        <TransactionDetails />
                    </ExplorerCard>
                )
            },

            {
                path: 'index/:id',
                element: <PaymentIndexManage />
            },
            {
                path: 'card/:id/:type',
                element: <PaymentCardView />
            },
            {
                path: 'card/:id',
                element: <PaymentCardView />
            },
            {
                path: 'pending',
                element: <PaymentPendingView />
            }
        ]
    },
    {
        path: 'payment-reports',
        element: <MainLayout />,
        children: [{ path: '', element: <PageUnderConstruction /> }]
    },
    {
        path: 'payment-data-transfer',
        element: <MainLayout />,
        children: [{ path: '', element: <PageUnderConstruction /> }]
    },
    {
        path: '',
        element: <MainLayout />,
        children: []
    },
    {
        path: 'competitions',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <CommonIndex />
            },
            {
                path: 'calendar',
                element: <CompetitionsDetailsView />
            },
            {
                path: 'details/manage/:id',
                element: <CompetitionDetailsManage />
            },
            {
                path: 'participations',
                element: <CompetitionParticipationsView />
            },
            {
                path: 'participations2',
                element: <CompetitionParticipationsIntentionToEnterView />
            },
            {
                path: 'participations/:id',
                element: <ParticipationsExplorer />
            },
            {
                path: 'participations2/:id',
                element: <ParticipationsIntentionToEnterExplorer />
            },
            {
                path: 'participations/manage/:id',
                element: <CompetitionsParticipationsManage />
            },
            {
                path: 'participations2/manage/:id',
                element: <CompetitionsParticipationsIntentionToEnterManage />
            },

            {
                path: 'participationslist',
                element: <ParticipationsListCompetitions />
            },
            {
                path: 'participationslist/:id',
                element: <ParticipationsListParticipations />
            }
        ]
    },
    {
        path: 'results',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <CompetitionDetailsResultsView />
            },
            { path: 'details', element: <CompetitionDetailsResultsManage /> }
        ]
    },
    {
        path: 'performance',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <PageUnderConstruction />
            }
        ]
    },
    {
        path: 'certifications',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <PageUnderConstruction />
            }
        ]
    },

    {
        path: 'sites',
        element: <MainLayout />,
        children: [
            {
                path: 'view',
                element: <SitesView />
            },
            {
                path: 'manage/:id',
                element: <SitesManage />
            }
        ]
    },
    {
        path: 'faq',
        element: <MainLayout />,
        children: [
            {
                path: 'view',
                element: <FaqView />
            },
            {
                path: 'manage',
                element: <FaqManage />
            },
            {
                path: 'edit/:id',
                element: <FaqEdit />
            }
        ]
    }
];

export default MainRoutes;
