import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { ChangeUsernameDialog } from '../../../../ui-component/change-username/change-username-dialog';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
    Avatar,
    Fade
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Transitions } from 'qubCommon/Other/Transitions';
import { IconLogout, IconSettings, IconUserCircle, IconWriting } from '@tabler/icons';
import { useMe } from 'hooks/useMe';
import { useTranslation } from 'react-i18next';
import { useLogout } from 'hooks/useLogOut';
import { useQuery } from 'react-query';
import { dashboard, person } from 'apis';
import Conditional from 'common/Conditional';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { whoami } = useMe();
    const { t } = useTranslation();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const { logout } = useLogout();
    const [avatar, setAvatar] = useState('');
    // const [changeEmailOpen, setChangeEmailOpen] = useState(false);

    const anchorRef = useRef(null);

    const statistics = useQuery(['userId'], async () => {
        const result = await dashboard.statistics();
        return result;
    });

    useEffect(() => {
        const fetchAvatar = async () => {
            try {
                const res = await person.getAvatar({
                    projectTableId: 2,
                    recordId: statistics.data[2].Data[0].recordId
                });
                if (res !== '') {
                    setAvatar(res);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchAvatar();
    }, [statistics.status]);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setSelectedIndex(-1);
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            {/* <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                label={
                    whoami?.firstName ? (
                        <Typography variant="h3" style={{ color: `#673ab7d9` }}>
                            {`${whoami?.firstName?.substring(0, 1)}${whoami?.lastName?.substring(0, 1)}`}
                        </Typography>
                    ) : (
                        <IconUserCircle stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />
                    )
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            /> */}
            <Conditional condition={avatar !== ''}>
                <Avatar
                    sx={{
                        width: '48px',
                        height: '48px',
                        cursor: 'pointer',
                        borderWidth: 4,
                        borderStyle: 'solid',
                        borderColor: open ? `#99dfe380` : 'transparent'
                    }}
                    src={avatar}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                />
            </Conditional>
            <Conditional condition={avatar === ''}>
                <Avatar
                    sx={{ bgcolor: `#99dfe3`, width: '48px', height: '48px' }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <Typography variant="h3" style={{ color: `#673ab7d9` }}>
                        {`${whoami?.firstName?.substring(0, 1)}${whoami?.lastName?.substring(0, 1)}`}
                    </Typography>
                </Avatar>
            </Conditional>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    // <Transitions in={open} {...TransitionProps}>
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                {/* <Typography variant="h4">{t('GoodMorning')},</Typography> */}
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 600 }}>
                                                    {`${whoami?.firstName} ${whoami?.lastName}`}
                                                </Typography>
                                            </Stack>
                                            <Typography>{`${whoami?.roles?.join(', ')}`}</Typography>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ p: 2 }}>
                                        <Divider />
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 300,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 0}
                                                onClick={(event) => handleListItemClick(event, 0, '/user/settings')}
                                            >
                                                <ListItemIcon>
                                                    <IconSettings stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">{t('AccountSettings')}</Typography>} />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 4}
                                                onClick={logout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">{t('Logout')}</Typography>} />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
