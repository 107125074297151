import { useState, useRef } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Facebook, Instagram, Language, MailOutline, YouTube } from '@mui/icons-material';
import LanguageSelector from './LanguageSelector';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';

interface SocialsBarProps {
    open: boolean;
    email?: string;
}

const SocialsBar = (porps: SocialsBarProps) => {
    const { open, email } = porps;
    const anchorRef = useRef(null);
    const { language } = useSelector((state: RootState) => state.customization);
    const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false);

    const handleSendEmail = () => {
        window.open(`mailto:${email}`, '_blank');
    };

    const handleOpenLanguage = () => {
        setOpenLanguageMenu((prev) => !prev);
    };

    const languageToString = (language: 'gre' | 'en') => {
        if (language === 'gre') {
            return 'ΕΛ';
        }
        if (language === 'en') {
            return 'EN';
        }
        return '';
    };

    return (
        <Box
            sx={{
                height: open ? 35 : 0,
                opacity: open ? 1 : 0,
                background: '#7eaaca',
                alignItems: 'center',
                justifyContent: 'flex-end',
                display: 'flex',
                transition: 'height 0.4s ease, opacity 0.4s ease',
                paddingRight: '6vw',
                overflow: 'hidden'
            }}
        >
            <Stack direction="row">
                <IconButton>
                    <Facebook sx={{ color: '#f0f0f0' }} />
                </IconButton>
                <IconButton>
                    <Instagram sx={{ color: '#f0f0f0' }} />
                </IconButton>
                <IconButton>
                    <YouTube sx={{ color: '#f0f0f0' }} />
                </IconButton>
                <IconButton onClick={handleSendEmail}>
                    <MailOutline sx={{ color: '#f0f0f0' }} />
                </IconButton>
                <IconButton onClick={handleOpenLanguage} ref={anchorRef}>
                    <Stack direction="row" spacing={1}>
                        <Language sx={{ color: '#f0f0f0' }} />
                        <Typography fontSize="large" sx={{ color: '#f0f0f0' }}>
                            {languageToString(language)}
                        </Typography>
                    </Stack>
                    <LanguageSelector anchorRef={anchorRef} open={openLanguageMenu} setOpen={setOpenLanguageMenu} language={language} />
                </IconButton>
            </Stack>
        </Box>
    );
};

export default SocialsBar;
