import { Grid } from '@mui/material';

const ExplorerCard = ({ children }) => {
    return (
        <Grid
            sx={{
                maxHeight: `calc(100vh - 104px)`,
                padding: 2,
                height: `calc(100vh - 104px)`,
                background: '#fefefe',
                borderRadius: 1,
                overflowY: 'auto',
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column'
            }}
        >
            {children}
        </Grid>
    );
};

export default ExplorerCard;
