import { FlexGrid, FlexGridColumn} from '@grapecity/wijmo.react.grid';
import { Button } from '@mui/material';
import { useRef, useState } from 'react';
import { configuration } from 'apis';
import { useQuery } from 'react-query';

const EventCategories = () => {
    const eventCategoriesGridRef = useRef();
    const [eventCategoryChanges, setEventCategoryChanges] = useState({});
    const [originalEventCategory, setOriginalEventCategory] = useState({});
    const [isEventCategoryChanged, setIsEventCategoryChanged] = useState(false);

    const dataQuery = useQuery(['eventCategories'], async () => {
        const result = await configuration.getEventCategories();
        console.log('result', result);
        return result;
    });

    const handleEventCategories = () => {
        const eventCategory = Object.values(eventCategoryChanges);
        configuration.postEventCategories(eventCategory.map((item) => ({...item, isOlympic: !!item.isOlympic})));
        setEventCategoryChanges({});
        setOriginalEventCategory(prevOriginals => ({...prevOriginals, ...eventCategoryChanges}));
        setIsEventCategoryChanged(false);
        console.log('event category', eventCategory);
    };

    const trackChanges = (gridRef, setChanges, setChanged, originalData) => {
        const grid = gridRef.current;

        grid.cellEditEnding.addHandler((s, e) => {
            const editedItem = s.rows[e.row].dataItem;
            const editedField = s.columns[e.col].binding;
            let editedValue = s.activeEditor.value;

            if (editedField === 'isOlympic') {
                editedValue = s.activeEditor.checked;
            }

            const originalValue = originalData[editedItem.id]?.[editedField];  

            if (editedValue !== originalValue || editedItem[editedField] !== originalValue) {
                setChanges((prevChanges) => {
                    const updatedChanges = { ...prevChanges };
                    updatedChanges[editedItem.id] = {
                        ...editedItem,
                        [editedField]: editedValue,
                    };
                    return updatedChanges;
                });
                setChanged(true);
            }
        });
    };

    const initializeEventCategory = (flex) => {
        eventCategoriesGridRef.current = flex;
        flex.collectionView.trackChanges = true;
        const originalData = {};
        flex.collectionView.itemsEdited.forEach((item) => {
            originalData[item.id] = { ...item};
        });
        setOriginalEventCategory(originalData);

        trackChanges(eventCategoriesGridRef, setEventCategoryChanges, setIsEventCategoryChanged, originalData);
    };

    const initialize = (flex, ref) => {
        ref.current = flex;
    };

    return {
        loading: <p>Loading...</p>,
        success: (
            <>
                <div style={{ textAlign: 'center', fontSize: 30,  fontWeight: 'bold', margin: '20px' }}>
                    <h1>Event Categories</h1>
                </div>
                <FlexGrid 
                    initialized={(flex) => initializeEventCategory(flex)}
                    className="explorer-grid"
                    itemsSource={dataQuery.data}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                >
                    <FlexGridColumn header="Id" binding="id" isReadOnly={true}/>
                    <FlexGridColumn header="Code" binding="code" isReadOnly={true}/>
                    <FlexGridColumn header="EventId" binding="eventId"/>
                    <FlexGridColumn header="Event Name" binding="eventName" isReadOnly={true}/>
                    <FlexGridColumn header="Event Abbr" binding="eventAbbr" isReadOnly={true}/>
                    <FlexGridColumn header="Event Total Athletes" binding="eventTotalAthletes" isReadOnly={true}/>
                    <FlexGridColumn header="Event Is Team" binding="eventIsTeam" isReadOnly={true}/>
                    <FlexGridColumn header="Age Category Id" binding="ageCategoryId"/>
                    <FlexGridColumn header="Age Category Name" binding="ageCategoryName" isReadOnly={true}/>
                    <FlexGridColumn header="Age Category Abbr" binding="ageCategoryAbbr" isReadOnly={true}/>
                    <FlexGridColumn header="Age Category Gender" binding="ageCategoryGender" isReadOnly={true}/>
                    <FlexGridColumn header="Age Category Age From" binding="ageCategoryAgeFrom" isReadOnly={true}/>
                    <FlexGridColumn header="Age Category Age To" binding="ageCategoryAgeTo" isReadOnly={true}/>
                    <FlexGridColumn header="Age Category Is Active" binding="ageCategoryIsActive" isReadOnly={true}/>
                    <FlexGridColumn header="Division Id" binding="divisionId"/>
                    <FlexGridColumn header="Division Name" binding="divisionName" isReadOnly={true}/>
                    <FlexGridColumn header="Division Abbr" binding="divisionAbbr" isReadOnly={true}/>
                    <FlexGridColumn header="Division Position" binding="divisionPosition" isReadOnly={true}/>
                    <FlexGridColumn header="Additional Description" binding="additionalDescr"/>
                    <FlexGridColumn header="Is Olympic" binding="isOlympic" dataType={3}/>
                    <FlexGridColumn header="Distance From" binding="distanceFrom"/>
                    <FlexGridColumn header="Distance To" binding="distanceTo"/>
                    <FlexGridColumn header="Distance Description" binding="distanceDescr"/>
                    <FlexGridColumn header="Weight From" binding="weightFrom"/>
                    <FlexGridColumn header="Weight To" binding="weightTo"/>
                    <FlexGridColumn header="Weight Description" binding="weightDescr"/>
                    <FlexGridColumn header="Height From" binding="heightFrom"/>
                    <FlexGridColumn header="Height To" binding="heightTo"/>
                    <FlexGridColumn header="Height Description" binding="heightDescr"/>
                </FlexGrid>
                {isEventCategoryChanged && (
                    <Button variant="contained" color="primary" onClick={handleEventCategories}>Save</Button>
                )}
            </>
        ),
        error: <p>ERROR</p>
    }[dataQuery.status];
};

export default EventCategories;