// material-ui
import { Skeleton, Stack, Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { useSelector } from 'react-redux';
// import menuItems from 'menu-items';
import { withSkeleton } from 'hoc/components/withSkeleton.jsx';
import { useMe } from 'hooks/useMe';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const NavGroupSkeleton = () => {
    return (
        <Stack spacing={1} style={{ marginTop: 12 }}>
            <Skeleton variant="text" width={100} height={24} />
            {[1, 2, 3, 4, 5].map((_, index) => (
                <Skeleton key={index} variant="rectangular" width={228} height={48} style={{ borderRadius: 12 }} />
            ))}
        </Stack>
    );
};

const NavGroupWithSkeleton = withSkeleton(NavGroup, NavGroupSkeleton);

const MenuList = () => {
    const { menuItems } = useSelector((state) => state.menu);
    const { status } = useMe();
    const navItems = menuItems?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroupWithSkeleton key={item.id} item={item} status={status} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
