import axios from 'axios';
import moment from 'moment';
import { store } from 'store';

const config = window.localStorage.getItem('CONFIG');
window.CONFIG = JSON.parse(config);
const baseURL = window.CONFIG?.AccessDbAPI;
const accessAxiosInstance = axios.create({
    baseURL
});

accessAxiosInstance.interceptors.request.use((config) => {
    if (config.data) {
        Object.keys(config.data).forEach(async (key) => {
            // Find a valid date object and format it
            if (config.data[key] instanceof Date && !Number.isNaN(config.data[key])) {
                config.data[key] = moment(config.data[key]).format('YYYY-MM-DDTHH:mm:ss.SSS');
            }
        });
    }
    const state = store.getState();
    if (!config.headers.Authorization) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${state.auth.token}`
        };
    }
    return config;
});

export default accessAxiosInstance;
