import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const FullNameSection = ({ firstName = '', lastName = '' }) => {
    return <Typography sx={{ mr: 2 }}>{`${firstName} ${lastName}`}</Typography>;
};

export default FullNameSection;

FullNameSection.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
};
