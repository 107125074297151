import { store } from 'store';
import axiosInstance from '../accessAxiosInstance';

const state = store.getState();

export const accessApi = {
    post: (path, params, options, cancelToken) =>
        axiosInstance
            .post(`${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token,
                headers: {
                    Authorization: `Bearer ${state.auth.token}`,
                    Accept: 'application/msaccess'
                }
            })
            .then((res) => {
                // if (res?.data?.error || res?.data?.status === 'error') throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, options, cancelToken) =>
        axiosInstance
            .get(`${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token,
                headers: {
                    Authorization: `Bearer ${state.auth.token}`,
                    Accept: 'application/msaccess'
                }
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const downloadMDB = async (params, options) => accessApi.get('/Export/DownloadMDB', params, options);
