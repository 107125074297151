/* eslint-disable */
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { useEffect, memo } from 'react';
import * as wjInput from '@grapecity/wijmo.input';
import * as wjcCore from '@grapecity/wijmo';
import { toggleClass } from '@grapecity/wijmo';
import './criteria.css';

const ValueListGrid = memo((props) => {
    const { gridRefs, name, handleDirtyCheck } = props;

    const valueListGridInit = (flex) => {
        //collapse everything
        flex.hostElement.addEventListener('dblclick', function (e) {
            flex.finishEditing();
        });

        //hide id
        flex.columns?.forEach((c) => {
            if (c.binding === 'id') c.visible = false;
        });

        flex.itemFormatter = (panel, r, c, cell) => {
            toggleClass(cell, 'criteria', true);
            if (flex.rows[r]?.level === 0) {
                toggleClass(cell, 'criteriaBold', true);
                const children = flex?.rows[r]?.dataItem?.children;
                let dirtyChildren = 0;
                children?.forEach((child) => {
                    if (child.isSelected) dirtyChildren += 1;
                });
                if (dirtyChildren && dirtyChildren < children?.length) toggleClass(cell, 'criteriaRed', true);
            }
        };
        //handle cell edit and check all

        flex.onCellEditEnded = (e) => {};

        flex.formatItem.addHandler((s, e) => {
            flex.onCellEditEnded = (e) => {
                const grid = e.panel.grid;
                const row = s.rows[e.row];
                const editingCol = grid.columns[e.col];
                const currentItem = grid.rows[e.row].dataItem;
                const value = currentItem?.isSelected;
                handleDirtyCheck();
                // if (currentItem?.children) {
                //     currentItem?.children.forEach((child) => {
                //         const rows = flex.rows;
                //         const updateRow = rows.find((r) => r.dataItem.value == child.value);
                //         flex.setCellData(updateRow.index, editingCol.index, value);
                //     });
                // }
                if (row.hasChildren) {
                    var curRowIdx = row.index + 1,
                        childRow = s.rows[curRowIdx++];
                    while (childRow && childRow.level > row.level) {
                        flex.setCellData(childRow.index, editingCol.index, value);
                        childRow = s.rows[curRowIdx++];
                    }
                }
                flex.collectionView.items.forEach((item, index) => {
                    if (item.children) {
                        let dirtyChildren = 0;
                        item.children.forEach((child) => {
                            if (child.isSelected) {
                                dirtyChildren += 1;
                            }
                        });
                    }
                });
            };
            flex.rows.forEach((r) => {
                r.isReadOnly = false;
            });
            flex.columns.forEach((c) => {
                if (c.binding !== 'isSelected') c.isReadOnly = true;
            });
            // replace collapse button with | + | AND | - |
            var row = s.rows[e.row];
            if (s.cells === e.panel && row instanceof wjcGrid.GroupRow) {
                // remove this class to remove the icons
                var classToRemove = 'wj-glyph-' + (row.isCollapsed ? 'right' : 'down-right');
                // change icon according to font awsome icons
                var classToAdd = row?.isCollapsed ? 'fa-sharp fa-plus boldim' : 'fa-minus boldim';
                var span = e.cell.querySelector('span.' + classToRemove);
                if (span) {
                    wjcCore.removeClass(span, classToRemove);
                    wjcCore.createElement(`<i class="fa ${classToAdd}"></i>`, span);
                }
            }
        });
        flex.autoSizeColumns(0, flex.columns.length);
        flex.autoSizeColumns(0, flex.columns.length, true);
        flex.autoSizeRows(0, flex.rows.length);
        flex.autoSizeRows(0, flex.rows.length, true);
        flex.collapseGroupsToLevel(0);
    };

    // Make checkboxes editable (Wijmo treegrid makes rows ReadOnly)
    const valueListGridLoadedRows = (flex) => {
        flex.collapseGroupsToLevel(0);
        flex.autoSizeColumns(0, flex.columns.length);
        flex.autoSizeColumns(0, flex.columns.length, true);
        flex.autoSizeRows(0, flex.rows.length);
        flex.autoSizeRows(0, flex.rows.length, true);
        flex.refreshCells();
    };

    const dataLines1 = [
        {
            id: 0,
            selected: false,
            name: 'Κριτήριο23',
            children: [{ id: 2, selected: false, name: 'λαλαλα' }]
        },
        { id: 3, selected: false, name: 'Κριτήριο2' },
        { id: 4, selected: false, name: 'Κριτήριο3' },
        {
            id: 5,
            selected: false,
            name: 'Κριτήριο23'
        }
    ];

    return (
        <FlexGrid
            ref={(el) => {
                if (!gridRefs?.current?.find((r) => r.name === name)) gridRefs.current.push({ grid: el, name });
            }}
            initialized={valueListGridInit}
            loadedRows={valueListGridLoadedRows}
            itemsSource={props?.data}
            selectionMode={wjcGrid.SelectionMode.ListBox}
            headersVisibility={0}
            childItemsPath="children"
            isReadOnly={false}
        >
            <FlexGridColumn width={25} isReadOnly />
            <FlexGridColumn binding="value" align="right" visible={false} width={0} />
            <FlexGridColumn header="Color" binding="isSelected" width={30} isReadOnly={false}></FlexGridColumn>
            <FlexGridColumn binding="title" minWidth={300} width="*" isReadOnly />
        </FlexGrid>
    );
});

export default ValueListGrid;
