import React, { createContext, useContext, useState, useEffect } from 'react';
import { generic } from 'apis';

const SettingsContext = createContext({});

// eslint-disable-next-line react/prop-types
const SettingsProvider = ({ children }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            await generic
                .getSettings()
                .then((res) => {
                    setData(res.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        fetchData();
    }, []);

    return <SettingsContext.Provider value={data}>{children}</SettingsContext.Provider>;
};

const useSettingsContext = () => {
    const context = useContext(SettingsContext);
    return context;
};

export { SettingsProvider, SettingsContext, useSettingsContext };
