export const styleExplorer = {
    main: { height: '100%', padding: 2.5 },
    content: {
        display: 'flex',
        flex: 1,
        overflowY: 'auto',
        flexDirection: 'column',
        padding: 0,
        marginTop: 2
    }
};
