import { memo } from 'react';

//   mui
import { Stack, Typography, useMediaQuery, useTheme, Skeleton } from '@mui/material';

// project impors
import { withSkeleton } from 'hoc/components/withSkeleton.jsx';
import Spice from 'qubCommon/Other/Spice';

//  utils
import { greetings } from 'utils';

// i18n
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

export const FullNameBaseComponent = memo((props) => {
    const { firstName, lastName } = props;
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));
    const date = new Date();
    const { t } = useTranslation();

    // Responsive Text Size
    const textVariant = () => (matchUpMd ? 'h2' : 'h4');
    return (
        <Stack spacing={1} direction="row">
            <Spice />
            <Typography variant={textVariant()}>{`${t(greetings(date), greetings(date))},`}</Typography>
            <Typography
                variant={textVariant()}
                sx={{
                    fontWeight: 400
                }}
            >
                {`${firstName} ${lastName}`}
            </Typography>
        </Stack>
    );
});

FullNameBaseComponent.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string
};

export const FullNameSkeleton = () => (
    <Stack direction="row" spacing={2}>
        <Skeleton variant="text" width={300} height={50} />
        <Skeleton variant="text" width={300} height={50} />
    </Stack>
);

const FullName = withSkeleton(FullNameBaseComponent, FullNameSkeleton);

export default FullName;
