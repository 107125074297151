import React, { useEffect, useRef } from 'react';
import { range } from 'lodash';
import { TextField, Stack } from '@mui/material';

const PinInput = ({ length, focus, inputStyle, type = 'numeric', onComplete }) => {
    const inputRefs = useRef([]);

    useEffect(() => {
        if (focus) {
            inputRefs.current[0].focus();
        }
    }, [focus]);

    const testPin = (value) => {
        const pinPattern = new RegExp(`^\\d{${length}}$`);
        return pinPattern.test(value);
    };

    const getPin = () => inputRefs.current.reduce((acc, ref) => acc + ref.value, '');

    const handleOnKeyDown = (e, index) => {
        switch (e.code) {
            case 'Backspace': {
                const currentValue = inputRefs.current[index].value;
                if (!currentValue && index > 0) {
                    inputRefs.current[index - 1].value = '';
                    inputRefs.current[index - 1].focus();
                }
                break;
            }
            case 'ArrowLeft': {
                e.preventDefault();
                if (index > 0) inputRefs.current[index - 1].focus();
                if (index === 0) inputRefs.current[length - 1].focus();
                break;
            }
            case 'ArrowRight': {
                e.preventDefault();
                if (index < length - 1) inputRefs.current[index + 1].focus();
                if (index === length - 1) inputRefs.current[0].focus();
                break;
            }
            case 'Home': {
                inputRefs.current[0].focus();
                break;
            }
            case 'End': {
                inputRefs.current[length - 1].focus();
                break;
            }
            default:
        }
    };

    const handleChange = (e, index) => {
        const newValue = e.target.value;
        const re = /\d/;
        if (re.test(newValue)) {
            if (index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        } else {
            inputRefs.current[index].value = '';
            return false;
        }

        const pin = getPin();
        if (testPin(pin)) {
            onComplete(pin);
        }
        return true;
    };

    const handleOnPaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('Text');
        if (testPin(text)) {
            text.split('').forEach((num, i) => {
                inputRefs.current[i].value = num;
            });
            inputRefs.current[length - 1].focus();

            onComplete(text);
        } else {
            console.error('Cannot paste this');
        }
    };

    return (
        <Stack direction="row" spacing={1}>
            {range(length).map((_, i) => (
                <TextField
                    key={i}
                    onKeyDown={(e) => handleOnKeyDown(e, i)}
                    onChange={(e) => handleChange(e, i)}
                    inputRef={(el) => {
                        inputRefs.current[i] = el;
                    }}
                    style={inputStyle}
                    inputProps={{
                        style: {
                            padding: 12,
                            fontSize: 20
                        },
                        maxLength: 1,
                        onPaste: handleOnPaste
                    }}
                    type={type}
                />
            ))}
        </Stack>
    );
};

export default PinInput;
