import axios from 'axios';

const config = window.localStorage.getItem('CONFIG');
window.CONFIG = JSON.parse(config);
const baseURL = window.CONFIG?.urlAPI;
const reportsAxiosInstance = axios.create({
    baseURL
});

export default reportsAxiosInstance;
