import format from 'date-fns/format';

export const convertGridToMobile = (gridRef, flex, columns) => {
    const groups = {};
    const visibleColumns = {};
    const formatColumns = {};
    try {
        if (gridRef.current) {
            gridRef.current.columns.forEach((c) => {
                visibleColumns[c?.binding] = c?.visible;
                if (c?.format) formatColumns[c?.binding] = c?.format;
            });
            /* eslint-disable */
            columns.forEach((group) => {
                groups[`group${group?.grpId}`] = group?.children
                    .map((child) => {
                        if (visibleColumns[child?.fldName]) return child?.fldName;
                    })
                    .filter((element) => element !== undefined);
            });
            const mobileData = gridRef.current.itemsSource;
            Object.keys(groups).forEach((group) => {
                if (groups[group]?.length === 0) delete groups[group];
            });
            const mobileGridData = mobileData.map((item) => {
                const newObj = {};

                Object.keys(groups).forEach((group) => {
                    newObj[group] = groups[group]
                        .filter((key) => {
                            if (key in item) {
                                return item[key];
                            }
                        })
                        .map((key) => {
                            if (key in item) {
                                try {
                                    if (formatColumns?.[key] === 'date') {
                                        return format(new Date(item[key]), 'dd/MM/yyyy');
                                    }
                                    if (formatColumns?.[key] === 'datetime') return format(new Date(item[key]), 'dd/MM/yyyy HH:mm');
                                } catch {
                                    return item[key];
                                }
                                return item[key];
                            } else {
                                return null;
                            }
                        });
                });
                newObj.allData = item;
                return newObj;
            });
            const formatedMobileGridData = mobileGridData.map((row) => {
                let htmlString = '';
                let allData = {};
                const formatedRow = {};
                Object.keys(row).forEach((group, index) => {
                    if (group !== 'allData') {
                        if (index !== 0) htmlString += `<p class="mobile-item">${row[group].join(', ')}</p><hr class="mobile-divider"/>`;
                        else
                            htmlString += `<p class="mobile-item"><strong style="font-size: 1.1em;">${row[group]
                                .slice(0, 2)
                                .join(', ')}</strong>, ${row[group]
                                .slice(2, row[group]?.length)
                                .join(', ')}</p><hr class="mobile-divider"/>`;
                    }
                    if (group === 'allData') allData = row[group];
                });
                formatedRow.htmlString = htmlString;
                formatedRow.allData = allData;
                formatedRow.supportLine = 'Support';
                return formatedRow;
            });
            flex.itemsSource = formatedMobileGridData;
        }
    } catch {
        console.log('error not ready yet');
    }
    flex.columns.forEach((col) => {
        col.visible = col.index === 0;
        col.width = '*';
    });
};
