import { PageWrapper } from 'layout/PublicLayout/Wrappers';
import View from './ClubView';
import ClubsGrid from './Components/ClubsGrid';
import { Suspense } from 'react';

export const PublicClubView = () => {
    return (
        <PageWrapper>
            <Suspense fallback={<div>Loading...</div>}>
                <View />
            </Suspense>
        </PageWrapper>
    );
};

export const PublicClubDetails = () => {
    return (
        <PageWrapper>
            <ClubsGrid />
        </PageWrapper>
    );
};

export default { PublicClubView, PublicClubDetails };
