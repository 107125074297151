import { createContext, useContext, useEffect, PropsWithChildren, useState } from 'react';
import { HubConnectionBuilder, LogLevel, HubConnection } from '@microsoft/signalr';
import { SIGNALR_PATH } from 'constants/appUrls';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import { useQueryClient } from 'react-query';
import { ON } from 'enums/signalrConnection';
import { SIGNALR_DEVICE_UNPAIRED, SIGNALR_DEVICE_PAIRED } from 'store/actions';
import { QrDialog } from './components';
import { useSnackbar } from 'notistack';
import { devices } from 'apis';
import { v4 as uuidv4 } from 'uuid';
import { ActionType } from 'enums/actionType';
import { RootState } from 'store/types';

interface MobileConnectionContextType {
    connection: HubConnection | null;
    openQrCode: () => void;
    requestPhoto: (handler?: (data: any) => void) => void;
}

const mccDefaultValues: MobileConnectionContextType = {
    connection: null,
    openQrCode: () => {},
    requestPhoto: () => {}
};

const MobileConnectionContext = createContext<MobileConnectionContextType>(mccDefaultValues);
const reactAppBaseUrl = import.meta.env.VITE_BASE_URL;

const MobileConnectionProvider = (props: PropsWithChildren) => {
    const { enqueueSnackbar } = useSnackbar();
    const url: string = `${reactAppBaseUrl}${SIGNALR_PATH}`;
    const auth = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const [openQrCode, setOpenQrCode] = useState<boolean>(false);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    // const [onDataReceived, setOnDataReceived] = useState<((data: any) => void) | null>(null);

    const client = useQueryClient();

    const handleOpenQrCode = () => {
        setOpenQrCode(true);
    };

    const handleCloseQrCode = () => {
        setOpenQrCode(false);
    };

    const handleOnInvalidateQuery = (data: any) => {
        client.invalidateQueries(data);
    };

    const handleOnDevicePaired = () => {
        setOpenQrCode(false);
        dispatch({ type: SIGNALR_DEVICE_PAIRED });
        enqueueSnackbar('Device paired successfully', { variant: 'success' });
    };

    const handleOnDeviceUnpaired = () => {
        dispatch({ type: SIGNALR_DEVICE_UNPAIRED });
        enqueueSnackbar('Device unpaired', { variant: 'success' });
    };

    const handleRequestPhoto = () => {
        // if (responseHandler) {
        //     setOnDataReceived(responseHandler);
        // }
        devices
            .requestAction({
                actionId: uuidv4().toString(),
                accessToken: auth.accessToken,
                actionType: ActionType.captureImage,
                payload: {}
            })
            .catch((err) => console.log(err));
    };

    const handleDataReceived = (data: any) => {
        console.log(data);
        // if (onDataReceived) {
        //     onDataReceived(data.data);
        // }
    };

    useEffect(() => {
        const isSessionPaired = async () =>
            await devices.isSessionPaired({ accessToken: auth.accessToken }).then((isPaired) => {
                console.log('isPaired', isPaired);
                if (isPaired) {
                    dispatch({ type: SIGNALR_DEVICE_PAIRED });
                } else {
                    dispatch({ type: SIGNALR_DEVICE_UNPAIRED });
                }
            });
        isSessionPaired();
    }, []);

    useEffect(() => {
        if (auth.isLoggedIn) {
            const connectionBuilder = new HubConnectionBuilder()
                .configureLogging(LogLevel.Information)
                .withUrl(url, { accessTokenFactory: () => auth.token })
                .withAutomaticReconnect();
            const connection = connectionBuilder.build();
            setConnection(connection);
        } else {
            setConnection(null);
            dispatch({ type: SIGNALR_DEVICE_UNPAIRED });
        }
    }, [auth]);

    useEffect(() => {
        if (connection) {
            if (connection.state === 'Disconnected') {
                connection.start();
            }

            connection.on(ON.devicePaired, handleOnDevicePaired);
            connection.on(ON.deviceUnpaired, handleOnDeviceUnpaired);
            connection.on(ON.invalidateQuery, handleOnInvalidateQuery);
            // connection.on(ON.actionResponse, (data) => handleDataReceived(data));
        }
        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, [connection]);

    const value: MobileConnectionContextType = { connection, openQrCode: handleOpenQrCode, requestPhoto: handleRequestPhoto };

    return (
        <MobileConnectionContext.Provider value={value}>
            {props.children}
            <QrDialog open={openQrCode} accessToken={auth.accessToken} onClose={handleCloseQrCode} />
        </MobileConnectionContext.Provider>
    );
};

const useMobileConnection = () => {
    const mobileConnectionContext = useContext(MobileConnectionContext);
    if (!mobileConnectionContext) {
        throw new Error('useMobileConnection must be used within a MobileConnectionProvider');
    }
    return mobileConnectionContext;
};

export { MobileConnectionProvider, useMobileConnection };
