import { combineReducers } from 'redux';

// reducer import
import authReducer from './authReducer';
import customizationReducer from './customizationReducer';
import menuReducer from './menuReducer';
import breadcrumbReducer from './breadcrumbReducer';
import signalrReducer from './signalrReducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    auth: authReducer,
    menu: menuReducer,
    breadcrumbs: breadcrumbReducer,
    signalr: signalrReducer
});

export default reducer;
