// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isOpen: ['Home.View'], // for active default menu
    opened: true,
    lockMenu: false,
    menuItems: [],
    loading: false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const menuReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.LOCK_MENU:
            return {
                ...state,
                lockMenu: action.lockMenu
            };
        case actionTypes.STORE_MENU:
            return {
                ...state,
                menuItems: action.menuItems
            };
        case actionTypes.RESET_MENU:
            return initialState;
        default:
            return state;
    }
};

export default menuReducer;
