import {
    Box,
    Grid,
    Button,
    Toolbar,
    useMediaQuery,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    Divider,
    Typography
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import { Logo } from 'ui-component';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

interface ToolBarProps {
    open: boolean;
    projectLogo: string;
    federationUrl?: string;
}

const ToolBar = (props: ToolBarProps) => {
    const { open, projectLogo, federationUrl } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));

    const height = useMemo(() => {
        if (lgUp) {
            return open ? 45 : 55;
        }
        return 'auto';
    }, [lgUp, open]);

    const handleNavMenu = (url: string) => {
        navigate(url);
    };

    const handleNavFederation = () => {
        window.open(federationUrl, '_blank');
        window.focus();
    };

    return (
        <Box
            sx={{
                height: height, // open ? 45 : 55,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                overflow: 'hidden',
                background: '#f8f9fa'
            }}
        >
            <Grid container xs={12} alignItems="center" sx={{ overflowX: 'hidden', overflowY: lgUp ? 'hidden' : 'visible' }}>
                <Grid
                    item
                    container
                    xs={1}
                    justifyContent="center"
                    sx={{
                        opacity: open ? 0 : 1,
                        transition: 'opacity 0.3s ease',
                        overflow: 'hiddden',
                        ml: lgUp ? 0 : 1
                    }}
                >
                    <Logo logo={projectLogo} dimensions={{ width: '5vh', height: '5vh' }} />
                </Grid>
                <Grid item xs={9}>
                    {lgUp ? (
                        <Toolbar>
                            <Button variant="text" color="inherit" onClick={() => handleNavMenu('/public/calendar')}>
                                {t('Calendar')}
                            </Button>
                            <Button variant="text" color="inherit" onClick={() => handleNavMenu('/public/athletes')}>
                                Athletes
                            </Button>
                            <Button variant="text" color="inherit" onClick={() => handleNavMenu('/public/officials')}>
                                Officials
                            </Button>
                            <Button variant="text" color="inherit" onClick={() => handleNavMenu('/public/clubs')}>
                                {t('Clubs')}
                            </Button>
                            <Button variant="text" color="inherit" onClick={handleNavFederation}>
                                {t('GoToFederation')}
                            </Button>
                        </Toolbar>
                    ) : (
                        <>
                            <Accordion sx={{ width: '100%', mt: 1, background: '#f8f9fa' }}>
                                <AccordionSummary
                                    expandIcon={<Menu />}
                                    sx={{
                                        flexDirection: 'row-reverse',
                                        borderTop: 'none',
                                        '& .MuiAccordionSummary-content': {
                                            marginLeft: 1
                                        },
                                        '& .MuiAccordionSummary-content.Mui-expanded': {
                                            marginLeft: 1
                                        },
                                        background: '#f8f9fa'
                                    }}
                                >
                                    <Typography fontSize="medium" fontWeight={400}>
                                        Menu
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack
                                        direction="column"
                                        alignItems="flex-start"
                                        spacing={1}
                                        sx={{ maxHeight: '50vh', overflowY: 'auto' }}
                                    >
                                        <Typography fontSize="medium" fontWeight={300} onClick={() => handleNavMenu('/public/calendar')}>
                                            {t('Calendar')}
                                        </Typography>
                                        <Typography fontSize="medium" fontWeight={300} onClick={() => handleNavMenu('/public/athletes')}>
                                            {t('Athletes')}
                                        </Typography>
                                        <Typography fontSize="medium" fontWeight={300} onClick={() => handleNavMenu('/public/officials')}>
                                            {t('Officials')}
                                        </Typography>
                                        <Typography fontSize="medium" fontWeight={300} onClick={() => handleNavMenu('/public/clubs')}>
                                            {t('Clubs')}
                                        </Typography>
                                        <Typography fontSize="medium" fontWeight={300} onClick={handleNavFederation}>
                                            {t('GoToFederation')}
                                        </Typography>
                                        <Divider />
                                        <Typography fontSize="medium" fontWeight={300} onClick={() => handleNavMenu('/login')}>
                                            {t('MemberLogin')}
                                        </Typography>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}
                </Grid>
                {lgUp && (
                    <Grid item xs={2} container justifyContent="flex-start" alignItems="center">
                        <Button variant="text" sx={{ color: '#7eaaca' }} onClick={() => handleNavMenu('/login')}>
                            <u>{t('MemberLogin')}</u>
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ToolBar;
