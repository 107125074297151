import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { isValidDate, isNil } from 'lodash';

const useDateTranslatorHelper = (date) => {
    const { t } = useTranslation();
    if (isNil(date)) return null;
    // if (!isValidDate(date)) return null;
    const day = format(new Date(date), 'dd');
    const month = format(new Date(date), 'MMM');
    const year = format(new Date(date), 'yyyy');
    return `${day} ${t(month)} ${year}`;
};

useDateTranslatorHelper.propTypes = {
    date: propTypes.date
};

export default useDateTranslatorHelper;
