import axiosInstance from '../axiosInstance';

export const apiEntitiesApi = {
    post: (path, params, cancelToken) =>
        axiosInstance
            .post(`/apiEntities${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                // if (res?.data?.error || res?.data?.status === 'error') throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/apiEntities${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const getData = async (params) => apiEntitiesApi.post('/getData', params);
