import { Grid, Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Logo } from 'ui-component';

interface LogoBarProps {
    open: boolean;
    projectLogo: string;
    projectTitle: string;
    address?: string;
}

const LogoBar = (props: LogoBarProps) => {
    const { open, projectLogo, projectTitle, address } = props;
    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Box
            sx={{
                height: open ? 95 : 0,
                opacity: open ? 1 : 0,
                alignItems: 'center',
                justifyContent: 'flex-start',
                display: 'flex',
                transition: 'height 0.4s ease, opacity 0.4s ease',
                paddingLeft: lgUp ? '12vw' : '24vw'
            }}
        >
            <Box sx={{ position: 'absolute', top: '2vh', left: '6vw' }}>
                <Logo logo={projectLogo} dimensions={{ width: '10vh', height: '10vh' }} />
            </Box>
            <Grid container xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h4" fontSize={lgUp ? 'x-large' : 'large'} fontWeight={400} sx={{ flexGrow: 1 }}>
                        {projectTitle}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" component="div" fontSize="medium" fontWeight={400} sx={{ flexGrow: 1 }}>
                        {address}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default LogoBar;
