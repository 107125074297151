import { useMobileConnection } from "context/SignalR/mobileActionsConnection";
import { Button } from "@mui/material";
import {useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { MobileFriendly, MobileOff } from "@mui/icons-material";
import { SIGNALR_DEVICE_UNPAIRED} from 'store/actions';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { devices } from "apis";
import { ActionType } from "enums/actionType";

const MobilePairSection = () => {
    const {openQrCode} = useMobileConnection();
    const signalr = useSelector((state: RootState) => state.signalr);;
    const auth = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    const handleUnpair = async () => {
        try {
            const response = await devices.unpairDeviceFromSession({
                actionId: uuidv4().toString(),
                accessToken: auth.accessToken,
                actionType: ActionType.unpairDevice,
            });
            if(response == true){
                dispatch({ type: SIGNALR_DEVICE_UNPAIRED });
            }
        } catch (error) {
            console.error("Error unpairing device:", error);
        }
    };

    return (
        <Button startIcon={signalr.isDevicePaired  ? <MobileFriendly/> : <MobileOff/>}
                onClick={signalr.isDevicePaired ? handleUnpair : openQrCode}
                sx={{background: signalr.isDevicePaired  ? 'green' : 'red', color: 'white',
                    '&:hover': {
                    background: signalr.isDevicePaired  ? 'lightgreen' : 'red', 
                    color: 'white',  
                }
                }}>
            {signalr.isDevicePaired ? 'Paired' : 'Pair Device'}
        </Button>
    );
}

export default MobilePairSection;