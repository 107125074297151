// material-ui
import { Card, Divider, Typography, useTheme } from '@mui/material';

// ==============================|| SKELETON - EARNING CARD ||============================== //

const OptionCard = ({ description, clickAction, selected, additionalInfo }) => {
    const theme = useTheme();

    return (
        <Card
            onClick={clickAction}
            sx={{
                mb: 2,
                p: 2,
                maxWidth: 800,
                borderRadius: '10px',
                cursor: 'pointer',
                backgroundColor: theme.palette.background.paper,
                '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : '#f8f8f8'
                }
            }}
        >
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        width: '3px',
                        backgroundColor: '#5E35B1',
                        marginRight: '15px',
                        marginLeft: '5px',
                        borderRadius: '9px'
                    }}
                >
                    {null}
                </div>
                <div style={{ margin: '10px' }}>
                    <Typography variant="h4">{description}</Typography>
                </div>
            </div>
            {selected ? <div style={{ margin: '20px 30px' }}>{additionalInfo}</div> : null}
        </Card>
    );
};

export default OptionCard;
