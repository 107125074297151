import { useDispatch, useSelector } from 'react-redux';
import { IconHelp } from '@tabler/icons';
import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { MENU_OPEN } from 'store/actions';
// project imports

const CustomNavItem = ({ file }) => {
    const customization = useSelector((state) => state.customization);
    const theme = useTheme();
    const { t } = useTranslation();
    const [Help, setHelp] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /* eslint-disable global-require */

    // useEffect(() => {
    //     if (file) {
    //         try {
    //             const Help = require(`assets/${file}`).default; // eslint-disable-line import/no-dynamic-require
    //             setHelp(Help);
    //         } catch {
    //             console.log('No help file found');
    //         }
    //     }
    // }, [file]);

    /* eslint-enable global-require */

    return (
        <ListItemButton
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                mb: 2,
                alignItems: 'flex-start',
                backgroundColor: `${theme.palette.primary} !important`,
                pl: `24px`
            }}
            selected={window.location.pathname === '/faq/view'}
            onClick={() => {
                dispatch({ type: MENU_OPEN, id: null });
                navigate('/faq/view');
            }}
            tabIndex={-1}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>
                <IconHelp />
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="subtitle2" color="inherit">
                        {t('Help')}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};

export default CustomNavItem;
