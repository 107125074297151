//  mui
import { Grid, Stack, Typography } from '@mui/material';

//  icons
import { IconLockAccess } from '@tabler/icons';

// colors
import colors from 'assets/scss/_themes-vars.module.scss';

export default () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Stack
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    direction="row"
                    sx={{
                        p: 2,
                        background: colors?.errorMain,
                        borderRadius: 1
                    }}
                >
                    <Typography variant="h2" color="#fefefe">
                        Restricted permission
                    </Typography>
                    <IconLockAccess size="2rem" color="#fefefe" />
                </Stack>
            </Grid>
        </Grid>
    );
};
