// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isLoggedIn: false,
    isEmailVerified: null,
    token: null,
    accessToken: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                ...action.payload,
                accessToken: action.payload.accessToken,
                isLoggedIn: true
            };
        case actionTypes.VERIFY_EMAIL:
            return {
                ...state,
                isEmailVerified: true
            };

        case actionTypes.REFRESH_TOKEN:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default authReducer;
