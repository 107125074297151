import { ClickAwayListener, FormControl, FormControlLabel, Popper, Radio, RadioGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SET_LANGUAGE } from 'store/actions';

const LanguageSelector = (props) => {
    const { open, setOpen, anchorRef, language } = props;
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const theme = useTheme();
    const [selectedLanguage, setLanguage] = useState(language);

    const handleLanguageChange = (value) => {
        setLanguage(value);
        dispatch({ type: SET_LANGUAGE, language: value });
        i18n.changeLanguage(value);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Popper placement="bottom-end" open={open} anchorEl={anchorRef.current} sx={{ zIndex: 9999, boxShadow: 2, px: 2 }}>
            <ClickAwayListener onClickAway={handleClose}>
                <FormControl>
                    <RadioGroup
                        aria-label="language"
                        value={selectedLanguage}
                        onChange={(e) => handleLanguageChange(e.target.value)}
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel
                            value="en"
                            control={<Radio />}
                            onClick={() => handleLanguageChange('en')}
                            label="English"
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                            }}
                        />
                        <FormControlLabel
                            value="gre"
                            control={<Radio />}
                            onClick={() => handleLanguageChange('gre')}
                            label="Ελληνικά"
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                            }}
                        />
                    </RadioGroup>
                </FormControl>
            </ClickAwayListener>
        </Popper>
    );
};

export default LanguageSelector;
