// project imports
import VerificationPrompt from 'views/verification';

import { PermissionDenied } from './components';

import { useMe } from 'hooks/useMe';
import { useMenu } from 'hooks/useMenu';
import propTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@mui/styles';

import { CircularProgress, Dialog } from '@mui/material';
import { useLoading } from 'hooks/useLoading';

const useStyles = makeStyles({
    root: {
        color: '#4D79BC' // Set the desired white color
    }
});

export const withPermission =
    (Component, { action, subject, unrestricted }) =>
    (props) => {
        const { whoami, isLoading, isError } = useMe();
        const isEmailVerified = whoami?.isEmailVerified;
        const hasPermission = whoami?.permissions?.can(action, subject);

        if (isLoading || isError) {
            return null;
        }
        if (!isEmailVerified) {
            return <VerificationPrompt />;
        }
        if (!hasPermission && !unrestricted) {
            return <PermissionDenied />;
        }
        return <Component {...props} />;
    };

/**
 *
 * @param {JSX.Element | null} Layout
 * @returns {JSX.Element | null}
 * Returns Enhanced React JSX Element which
 * sets the active menu item id
 */
export const withLayout = (Layout) => {
    /**
     *
     * @param {any} props
     * @returns {JSX.Element | null}
     * Returns Enhanced React JSX Element which
     * sets the active menu item id
     */
    const EnhancedLayout = (props) => {
        const { menuItemId } = props;
        const { setMenuItem } = useMenu();
        React.useEffect(() => {
            setMenuItem(menuItemId);
        }, [menuItemId, setMenuItem]);
        return <Layout {...props} />;
    };

    EnhancedLayout.propTypes = {
        menuItemId: propTypes.string
    };

    return EnhancedLayout;
};

export const LoadingDialog = () => {
    const classes = useStyles();
    const { loading } = useLoading();

    return (
        <Dialog
            open={loading}
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }
            }}
            BackdropProps={{ style: { backgroundColor: 'black', opacity: '0.65' } }}
        >
            <div style={{ padding: '30px' }}>
                <CircularProgress size={70} />
            </div>
        </Dialog>
    );
};
