import React from 'react';
import { Typography } from '@mui/material';

export function withSkeleton(WrappedComponent, SkeletonComponent) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const ComponentWithSkeleton = (props) => {
        const { status } = props;
        if (status === 'success') {
            return <WrappedComponent {...props} />;
        }
        if (status === 'loading') {
            return <SkeletonComponent {...props} />;
        }
        if (status === 'error') {
            return <Typography variant="subtitle1">There was an error fetching your data :(</Typography>;
        }
        return null;
    };

    ComponentWithSkeleton.displayName = `withSkeleton(${displayName})`;

    return ComponentWithSkeleton;
}
