import axiosInstance from '../axiosInstance';

export const genericApi = {
    post: (path, params, options, cancelToken) => {
        return axiosInstance
            .post(`/generic${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            });
    },
    get: (path, params, options, cancelToken) =>
        axiosInstance
            .get(`/generic${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const getFiles = async (params, options) => genericApi.post('/getFiles', params, options);

export const downloadFile = async (params, options) => genericApi.post('/downloadfile', params, options);

export const downloadFileAsBase64 = async (params) => genericApi.get('/downloadfileasbase64', { params });

export const removeFile = async (params, options) => genericApi.post('/removefile', params, options);

export const getAssets = async () => genericApi.post('/getassets');

export const getFilesDataKeyByAppTypeId = async (params) => genericApi.get('/filesDataKeyByAppTypeId', { params });

export const uploadFiles = async (params, options) => genericApi.post('/uploadfiles', params, options);

export const getAvatar = async (params) => genericApi.post('/getavatar', params);

export const removeAvatar = async (params) => genericApi.post('/removeavatar', params);

export const getSettings = async (params) => genericApi.post('/getsettings', params);
