import { createContext, useContext, useState, useRef } from 'react';
import { Stack, Box, Typography, Button } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { Popup } from '@grapecity/wijmo.react.input';
import { styleExplorer } from 'qubCommon/qubStyles';
import '@grapecity/wijmo.styles/wijmo.css';
import { FlexGrid } from '@grapecity/wijmo.react.grid';

const ModalImages = {
    Warning: 1,
    Question: 2,
    Error: 3,
    Info: 4
};

const ModalButtons = {
    OkCancel: 1,
    YesNo: 2,
    YesNoCancel: 3,
    OK: 4,
    Yes: 5,
    No: 6,
    Cancel: 7
};

const ModalContext = createContext({});

const ModalImage = ({ imageType }) => {
    if (imageType === ModalImages.Error) return <ErrorIcon style={{ fontSize: 54, color: 'red' }} />;
    if (imageType === ModalImages.Warning) return <WarningIcon style={{ width: 54, color: 'orange' }} />;
    if (imageType === ModalImages.Info) return <InfoIcon style={{ width: 54, color: 'blue' }} />;
    if (imageType === ModalImages.Question) return <QuestionMarkOutlinedIcon style={{ width: 54, color: 'blue' }} />;
    return <></>;
};

function getbuttonsArray(buttons) {
    let buttonsArray;
    switch (buttons) {
        case ModalButtons.YesNo:
            buttonsArray = [
                { label: 'Ναι', command: ModalButtons.Yes },
                { label: 'Οχι', command: ModalButtons.No }
            ];
            break;
        case ModalButtons.OK:
            buttonsArray = [{ label: 'OK', command: ModalButtons.OK }];
            break;
        case ModalButtons.OkCancel:
            buttonsArray = [
                { label: 'OK', command: ModalButtons.OK },
                { label: 'Cancel', command: ModalButtons.Cancel }
            ];
            break;
        case ModalButtons.YesNoCancel:
            buttonsArray = [
                { label: 'Ναι', command: ModalButtons.Yes },
                { label: 'Οχι', command: ModalButtons.No },
                { label: 'Ακύρωση', command: ModalButtons.Cancel }
            ];
            break;
        default:
            buttonsArray = [{ label: 'OK', command: ModalButtons.OK }];
            break;
    }

    return buttonsArray;
}

function ModalProvider({ children }) {
    const [title, setTitle] = useState(null);
    const [message, setMessage] = useState('');
    const [imageType, setImageType] = useState(null);
    const [defaultButton, setDefaultButton] = useState(null);
    const [isGrid, setIsGrid] = useState(false);
    const [gridData, setGridData] = useState(null);
    const popupRef = useRef();

    const popupInitialized = (popup) => {
        popup.isDraggable = true;
        popup.isResizable = true;
        popup.fadeOut = false;
        popup.fadeIn = false;

        popupRef.current = popup;
    };

    const gridLoadedRows = (flex) => {
        flex.autoSizeColumns();
    };

    const [buttons, setButtons] = useState([]);
    const ref = useRef();

    function openGrid(props) {
        setIsGrid(true);
        const { title, gridData } = props;
        setTitle(title);
        setGridData(gridData);

        popupRef.current.show(true);
        popupRef.current.centerPopup();
    }
    function openModal(props) {
        const { title, message, imageType, buttons, callback, defaultButton } = props;

        ref.current = {
            callback
        };
        setIsGrid(false);
        setDefaultButton(defaultButton);
        setButtons(getbuttonsArray(buttons));
        popupRef.current.show(true);

        setTitle(title);
        setMessage(message);
        setImageType(imageType);
        popupRef.current.hostElement.style.top = `calc(50% - ${popupRef.current.hostElement.offsetHeight / 2}px)`;
        popupRef.current.hostElement.style.left = `calc(50% - ${popupRef.current.hostElement.offsetWidth / 2}px)`;
    }

    const handleButtonClick = (command) => {
        setTitle(null);
        popupRef.current.hide();
        ref.current.callback(command);
        setIsGrid(false);
    };

    return (
        <ModalContext.Provider value={{ openModal, openGrid }}>
            <Popup initialized={popupInitialized} hideTrigger={0}>
                <Stack sx={{ ...styleExplorer.main, padding: 0 }}>
                    <div className="wj-dialog-header Qub-DatagridBringValueView" style={{}}>
                        <div>
                            <h6 style={{ marginBottom: 0, marginTop: 0 }}>{title}</h6>
                        </div>
                        <button type="button" style={{ cursor: 'pointer' }} tabIndex={-1} className="close wj-hide">
                            &times;
                        </button>
                    </div>
                    {!isGrid ? (
                        <>
                            <Box sx={styleExplorer.content}>
                                <Stack direction="row" alignItems="center">
                                    <ModalImage imageType={imageType} />
                                    <Box
                                        sx={{
                                            p: 1,
                                            whiteSpace: 'pre-wrap',
                                            overflowWrap: 'break-word'
                                        }}
                                    >
                                        <Typography>{message}</Typography>
                                    </Box>
                                </Stack>
                            </Box>

                            <Stack direction="row" justifyContent="center" spacing={2} marginBottom={2}>
                                {buttons.map((b) => {
                                    return (
                                        <Button
                                            sx={{
                                                '&:focus': {
                                                    border: 'solid'
                                                }
                                            }}
                                            // autoFocus={buttons?.length === 1 || b?.command === defaultButton}
                                            key={b.label}
                                            onClick={() => handleButtonClick(b.command)}
                                            variant="outlined"
                                        >
                                            {b.label}
                                        </Button>
                                    );
                                })}
                            </Stack>
                        </>
                    ) : (
                        <FlexGrid loadedRows={gridLoadedRows} itemsSource={gridData} />
                    )}
                </Stack>
            </Popup>

            {children}
        </ModalContext.Provider>
    );
}

function useModal() {
    const modalContext = useContext(ModalContext);
    if (!modalContext) {
        throw new Error('useModal must be used within LoadingProvider');
    }

    return modalContext;
}

export { ModalProvider, useModal, ModalButtons, ModalImages };
