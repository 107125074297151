import { Drawer, DrawerProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSettings } from 'hooks/useSettings';
import LogoBar from './LogoBar';
import SocialsBar from './SocialsBar';
import ToolBar from './ToolBar';
interface StyledHeaderProps extends DrawerProps {
    open?: boolean;
    drawerHeight?: number;
}

const StyledHeader = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'drawerHeight' && prop !== 'open'
})<StyledHeaderProps>(({ theme, open, drawerHeight }) => ({
    height: 'fit-content',
    flexShrink: 0,
    translateY: 0,
    visibility: 'visible',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
        height: 'fit-content',
        overflow: 'hidden',
        transform: 'none !important',
        visibility: 'visible !important',
        backgroundColor: theme.palette.background.paper,
        transition: theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: open ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen
        })
    },
    transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
    })
}));

interface HeaderProps extends StyledHeaderProps {
    open: boolean;
}

const Header = (props: HeaderProps) => {
    const { open } = props;
    const { url, email, projectLogo, projectFullTitle, address } = useSettings();
    const drawerHeight: number = 175;

    return (
        <StyledHeader open={open} drawerHeight={drawerHeight} variant="persistent" anchor="top">
            <SocialsBar open={open} email={email} />
            <LogoBar open={open} projectLogo={projectLogo} projectTitle={projectFullTitle} address={address} />
            <ToolBar open={open} projectLogo={projectLogo} federationUrl={url} />
        </StyledHeader>
    );
};

export default Header;
export type { StyledHeaderProps };
