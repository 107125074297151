import React from 'react';
import { Checkbox, FormControlLabel, Typography, Stack } from '@mui/material';
import { useFormContext, useController } from 'react-hook-form';

const FormCheckbox = ({ name, label, defaultValue, ...other }) => {
    const { control } = useFormContext();
    const { field, fieldState } = useController({ name, control, defaultValue });

    return (
        <Stack>
            <FormControlLabel
                control={
                    /* eslint-disable */
                    <Checkbox checked={field.value} onChange={field.onChange} name={field.name} {...other} />
                }
                label={<Typography sx={{ userSelect: 'none' }}>{label}</Typography>}
            />
            {!!fieldState.error && <Typography sx={{ color: '#f44336' }}>{fieldState.error?.message}</Typography>}
        </Stack>
    );
};

export default FormCheckbox;
