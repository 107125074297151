import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import decodeToken from 'utils/decode-token';
import { Button } from '@mui/material';
import { useQueryClient } from 'react-query';
import { LOGIN, STORE_MENU } from '../../../../store/actions';
import { useTranslation } from 'react-i18next';

// Apis
import { admin } from '../../../../apis';

const ImpersonationSection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);

    const data = decodeToken(token);

    const onSubmit = async () => {
        await admin
            .exitImpersonation()
            .then((res) => {
                dispatch({ type: STORE_MENU, menuItems: res?.menu });
                dispatch({ type: LOGIN, payload: res });
                queryClient.invalidateQueries('me');
                enqueueSnackbar(t('LoginSuccess'), {
                    variant: 'success'
                });
                navigate('/home');
            })
            .catch((error) => {
                enqueueSnackbar(error?.data?.message || t('SomethingWrong'), {
                    variant: 'error'
                });
            });
    };

    return (
        <>
            {data?.original_name ? (
                <Button type="submit" onClick={onSubmit} variant="contained" disableElevation>
                    Exit Impersonation
                </Button>
            ) : null}
        </>
    );
};

export default ImpersonationSection;
