import { Button, DialogTitle, Dialog, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import 'react-image-crop/dist/ReactCrop.css';
import propTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

const CustomDialog = ({ open, onClose, onAccept, meta, content, btnDisableOnClick = true, ...props }) => {
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    useEffect(() => {
        setButtonsDisabled(false);
    }, [open]);

    const debouncedOnAccept = useMemo(onAccept && typeof onAccept === 'function' ? () => debounce(onAccept, 300) : () => {}, [onAccept]);

    const debouncedOnClose = useMemo(onClose && typeof onClose === 'function' ? () => debounce(onClose, 300) : () => {}, [onClose]);

    const handleAccept = () => {
        setButtonsDisabled(true);
        debouncedOnAccept();
    };

    const handleClose = async () => {
        setButtonsDisabled(true);
        debouncedOnClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                {...props}
            >
                <DialogTitle id="alert-dialog-title">{meta.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{meta.description}</DialogContentText>
                    {content}
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAccept} disabled={btnDisableOnClick && buttonsDisabled} color="primary">
                        {meta.action}
                    </Button>
                    <Button
                        onClick={handleClose}
                        disabled={btnDisableOnClick && buttonsDisabled}
                        color="primary"
                        variant="contained"
                    >
                        {meta.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

CustomDialog.propTypes = {
    open: propTypes.bool,
    onClose: propTypes.func,
    onAccept: propTypes.func,
    meta: propTypes.object,
    content: propTypes.element,
    props: propTypes.any
};

export default CustomDialog;
