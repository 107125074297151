/* eslint-disable */
import React, { useState, useEffect } from 'react';
// mui
import { Stack, Tooltip, Typography, InputAdornment } from '@mui/material';

// hook forms
import { useFormContext } from 'react-hook-form';

import { CssTextField } from './CssTextField';
import RowLabel from './RowLabel';
import ColumnLabel from './ColumnLabel';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { useSelector } from 'react-redux';
import { toUppercase } from 'qubCommon/helperMethods/inputHelper';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const TextInput = (props) => {
    const {
        register,
        formState: { errors },
        setValue
    } = useFormContext();
    // const { errors } = formState;
    const { fieldsVariant } = useSelector((state) => state.customization);

    const {
        name,
        rules,
        rowDirection = false,
        label,
        labelWidth,
        variant = fieldsVariant ?? 'standard',
        defaultValue,
        innerRef,
        blurFn,
        tooltip,
        autoCapitalize = false,
        changeFn = () => {},
        startAdornment,
        endAdornment,
        defaultFromProps = true,
        ...other
    } = props;

    useEffect(() => {
        if (defaultFromProps) {
            setValue(name, defaultValue);
        }
    }, [setValue, name, defaultValue]);

    // const [helpColor, setHelpColor] = useState("black");

    const handleChange = (e) => {
        const { value } = e.target;
        setValue(name, autoCapitalize ? toUppercase(value) : value);
        changeFn();
    };

    return (
        <Stack direction={rowDirection ? 'row' : 'column'}>
            {variant === 'standard' && (
                <>
                    {rowDirection ? ( // variant = 'standard'
                        <RowLabel labelWidth={labelWidth} label={label} />
                    ) : (
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <ColumnLabel label={label} />
                            {tooltip && (
                                <Tooltip title={tooltip}>
                                    <HelpOutlineIcon fontSize="small" />
                                </Tooltip>
                            )}
                        </Stack>
                    )}
                </>
            )}
            <CssTextField
                ref={innerRef}
                inputProps={{
                    ...register(name, rules),
                    onChange: handleChange
                }}
                label={variant === 'standard' ? null : label} // variant = 'outlined
                variant={variant}
                InputProps={{
                    disableUnderline: true,
                    startAdornment:
                        typeof startAdornment === 'string' ? (
                            <InputAdornment position="start">
                                <Typography variant="body2">{startAdornment}</Typography>
                            </InputAdornment>
                        ) : (
                            startAdornment
                        ),
                    endAdornment:
                        typeof endAdornment === 'string' ? (
                            <InputAdornment position="end">
                                <Typography variant="body2">{endAdornment}</Typography>
                            </InputAdornment>
                        ) : (
                            endAdornment
                        )
                    // endAdornment: (
                    //     <Tooltip title="I need help">
                    //         <HelpIcon />
                    //     </Tooltip>
                    // )
                }}
                error={!!errors[name]}
                // onFocus={() => setHelpColor("orange")}
                onBlur={() => {
                    if (blurFn) blurFn();
                }}
                helperText={errors[name]?.message}
                required={rules?.required}
                size="small"
                InputLabelProps={{ fontSize: '1.25em' }}
                fullWidth
                {...other}
            />
        </Stack>
    );
};

export default TextInput;
