/* eslint-disable import/no-cycle */
import axiosInstance from '../axiosInstance';

export const adminApi = {
    post: async (path, params, cancelToken) =>
        axiosInstance
            .post(`/admin${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err.error ? err : err?.response;
            }),
    get: async (path, params, cancelToken) =>
        axiosInstance
            .get(
                `/admin${path}`,
                { params },
                {
                    cancelToken: cancelToken?.token
                }
            )
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response;
            })
};

export const impersonate = async (params) => adminApi.post('/impersonate', params);
export const exitImpersonation = async () => adminApi.post('/exitImpersonation');
export const qubTablesBackOffice = async () => adminApi.get('/qubTablesBackOffice');
export const getAppSettings = async () => adminApi.get('/getAppSettings');
export const postSettings = async (params) => adminApi.post('/postSettings', params);
export const postAppSettings = async (params) => adminApi.post('/postAppSettings', params);