import { createTheme } from '@mui/material/styles';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import { THEME_PRESETS } from './presets';
import { light } from '@mui/material/styles/createPalette';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
    // const color = THEME_PRESETS[customization?.theme ?? 'light'].then((res) => res.default);

    const themeOption = {
        // colors: color,
        heading: '#212121', // color?.grey900,
        paper: '#fff', // color?.paper,
        backgroundDefault: '#f0f0f0', // color?.background,
        background: '#e3f2fd', // color?.primaryLight,
        darkTextPrimary: '#616161', // color?.grey700,
        darkTextSecondary: '#9e9e9e', // color?.grey500,
        textDark: '#212121', // color?.grey900,
        menuSelected: '#5e35b1', // color?.secondaryDark,
        menuSelectedBack: '#2196f3', //color?.primaryMain,
        divider: '#eee', // color?.grey200,
        mode: 'light', // color?.mode ?? 'light',
        white: '#fff', //color?.white,
        black: '#000' // color?.black
    };

    const themeOptions = {
        direction: 'ltr',
        palette: {
            primary: {
                main: '#2196f3',
                light: '#e3f2fd',
                dark: '#1e88e5',
                contrastText: '#fefefe'
            },
            secondary: {
                main: '#673ab7',
                light: '#ede7f6',
                dark: '#5e35b1',
                contrastText: '#fefefe'
            },
            error: {
                main: '#d32f2f',
                light: '#ef5350',
                dark: '#c62828'
            },
            warning: {
                main: '#ffe57f',
                light: '#ffeb3b',
                dark: '#ffc107'
            },
            info: {
                main: '#2196f3',
                light: '#e3f2fd',
                dark: '#1e88e5'
            },
            success: {
                main: '#2e7d32',
                light: '#4caf50',
                dark: '#1b5e20'
            },
            background: {
                default: '#f0f0f0',
                paper: '#ffffff'
            },
            common: {
                black: '#010101',
                white: '#fefefe'
            },
            mode: 'light'
        }, // themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(themeOption)
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
