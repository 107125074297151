import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { Button } from '@mui/material';
import { isNil } from 'lodash';
import {configuration } from 'apis';
import {useQuery } from 'react-query';
import { useRef, useState } from 'react';

const ChecklistScreen = () =>{
    const checklistTables = useRef();
    
    const seasonGridRef = useRef();
    const [seasonChanges, setSeasonChanges] = useState({});
    const [originalSeason, setOriginalSeason] = useState({});
    const [isSeasonChanged, setIsSeasonChanged] = useState(false);
    
    const eventsGridRef = useRef();
    const [eventsChanges, setEventsChanges] = useState({});
    const [originalEvents, setOriginalEvents] = useState({});
    const [isEventsChanged, setIsEventsChanged] = useState(false);
    
    const applicationTypeGridRef = useRef();
    const [applicationTypeChanges, setApplicationTypeChanges] = useState({});
    const [originalApplicationType, setOriginalApplicationType] = useState({});
    const [isApplicationTypeChanged, setIsApplicationTypeChanged] = useState(false);
    
    const productServicesGridRef = useRef();
    const [productServicesChanges, setProductServicesChanges] = useState({});
    const [originalProductServices, setOriginalProductServices] = useState({});
    const [isProductServicesChanged, setIsProductServicesChanged] = useState(false);
    
    const fileTypesGridRef = useRef();
    const [fileTypesChanges, setFileTypesChanges] = useState({});
    const [originalFileTypes, setOriginalFileTypes] = useState({});
    const [isFileTypesChanged, setIsFileTypesChanged] = useState(false);

    const eventDataQuery = useQuery(['checklistScreenEvent'], async () => {
        const eventResult = await configuration.getLKEvents();
        console.log('eventResult', eventResult);
        return eventResult;
    });
    
    const seasonDataQuery = useQuery(['checklistScreenSeason'], async () => {
        const seasonResult = await configuration.getLKSeason();
        console.log('seasonResult', seasonResult);
        return seasonResult;
    });

    const productServicesDataQuery = useQuery(['checklistScreenProductServices'], async () => {
        const productServiceResult = await configuration.getProductServices();
        console.log('productServiceResult', productServiceResult);
        return productServiceResult;
    });

    const fileTypesDataQuery = useQuery(['checklistScreenFileTypes'], async () => {
        const fileTypesResult = await configuration.getFileTypes();
        console.log('fileTypesResult', fileTypesResult);
        return fileTypesResult;
    });

    const applicationTypeDataQuery = useQuery(['checklistScreenApplicationType'], async () => {
        const applicationTypeResult = await configuration.getApplicationType();
        console.log('applicationTypeResult', applicationTypeResult);
        return applicationTypeResult;
    });

    const optionalAnswerDataQuery = useQuery(['checklistScreenOptionalAnswers'], async () => {
        const optionalAnswerResult = await configuration.getOptionalAnswers();
        console.log('optionalAnswerResult', optionalAnswerResult);
        return optionalAnswerResult;
    });

    const questionDataQuery = useQuery(['checklistScreenQuestion'], async () => {
        const questionResult = await configuration.getQuestion();
        console.log('questionResult', questionResult);
        return questionResult;
    });

    const handleSeason = () => {
        const season = Object.values(seasonChanges);
        configuration.postSeason(season);
        setSeasonChanges({});
        setOriginalSeason(prevOriginals => ({...prevOriginals, ...seasonChanges}));
        setIsSeasonChanged(false);
        console.log('season', season);
    };

    const handleApplicationType = () => {
        const applicationType = Object.values(applicationTypeChanges);
        configuration.postApplicationType(applicationType);
        setApplicationTypeChanges({});
        setOriginalApplicationType(prevOriginals => ({...prevOriginals, ...applicationTypeChanges}));
        setIsApplicationTypeChanged(false);
        console.log('applicationType', applicationType);
    };

    const handleEvents = () => {
        const events = Object.values(eventsChanges);
        configuration.postEvents(events);
        setEventsChanges({});
        setOriginalEvents(prevOriginals => ({...prevOriginals, ...eventsChanges}));
        setIsEventsChanged(false);
        console.log('events', events);
    };

    const handleProductServices = () => {
        const productServices = Object.values(productServicesChanges);
        configuration.postProductServices(productServices);
        setProductServicesChanges({});
        setOriginalProductServices(prevOriginals => ({...prevOriginals, ...productServicesChanges}));
        setIsProductServicesChanged(false);
        console.log('productServices', productServices);
    };

    const handleFileTypes = () => {
        const fileTypes = Object.values(fileTypesChanges);
        configuration.postFileTypes(fileTypes);
        setFileTypesChanges({});
        setOriginalFileTypes(prevOriginals => ({...prevOriginals, ...fileTypesChanges}));
        setIsFileTypesChanged(false);
        console.log('fileTypes', fileTypes);
    };

    const trackChanges = (gridRef, setChanges, setChanged, originalData) => {
        const grid = gridRef.current;

        grid.cellEditEnding.addHandler((s, e) => {
            const editedItem = s.rows[e.row].dataItem;
            const editedField = s.columns[e.col].binding;
            let editedValue = s.activeEditor.value;

            if (editedField === 'isActive') {
                editedValue = s.activeEditor.checked;
            }

            const originalValue = originalData[editedItem.id]?.[editedField];  

            if (editedValue !== originalValue || editedItem[editedField] !== originalValue) {
                setChanges((prevChanges) => {
                    const updatedChanges = { ...prevChanges };
                    updatedChanges[editedItem.id] = {
                        ...editedItem,
                        [editedField]: editedValue,
                    };
                    return updatedChanges;
                });
                setChanged(true);
            }
        });
    };

    const initializeSeason = (flex) => {
        seasonGridRef.current = flex;
        flex.itemsSourceChanged.addHandler((grid) => {
            if (!isNil(grid.collectionView)) grid.collectionView.trackChanges = true;
        });

        if (!isNil(flex.collectionView)) flex.collectionView.trackChanges = true;
        const originalData = {};
        flex.collectionView?.itemsEdited.forEach((item) => {
            originalData[item.id] = { ...item};
        });
        setOriginalSeason(originalData);

        trackChanges(seasonGridRef, setSeasonChanges, setIsSeasonChanged, originalData);
    };

    const initializeApplicationType = (flex) => {
        applicationTypeGridRef.current = flex;
        flex.itemsSourceChanged.addHandler((grid) => {
            if (!isNil(grid.collectionView)) grid.collectionView.trackChanges = true;
        });

        if (!isNil(flex.collectionView)) flex.collectionView.trackChanges = true;
        const originalData = {};
        flex.collectionView?.itemsEdited.forEach((item) => {
            originalData[item.id] = { ...item};
        });
        setOriginalApplicationType(originalData);

        trackChanges(applicationTypeGridRef, setApplicationTypeChanges, setIsApplicationTypeChanged, originalData);
    };

    const initializeEvents = (flex) => {
        eventsGridRef.current = flex;
        flex.itemsSourceChanged.addHandler((grid) => {
            if (!isNil(grid.collectionView)) grid.collectionView.trackChanges = true;
        });

        if (!isNil(flex.collectionView)) flex.collectionView.trackChanges = true;
        const originalData = {};
        flex.collectionView?.itemsEdited.forEach((item) => {
            originalData[item.id] = { ...item};
        });
        setOriginalEvents(originalData);

        trackChanges(eventsGridRef, setEventsChanges, setIsEventsChanged, originalData);
    };

    const initializeProductServices = (flex) => {
        productServicesGridRef.current = flex;
        flex.itemsSourceChanged.addHandler((grid) => {
            if (!isNil(grid.collectionView)) grid.collectionView.trackChanges = true;
        });

        if (!isNil(flex.collectionView)) flex.collectionView.trackChanges = true;
        const originalData = {};
        flex.collectionView?.itemsEdited.forEach((item) => {
            originalData[item.id] = { ...item};
        });
        setOriginalProductServices(originalData);

        trackChanges(productServicesGridRef, setProductServicesChanges, setIsProductServicesChanged, originalData);
    };

    const initializeFileTypes = (flex) => {
        fileTypesGridRef.current = flex;
        flex.itemsSourceChanged.addHandler((grid) => {
            if (!isNil(grid.collectionView)) grid.collectionView.trackChanges = true;
        });

        if (!isNil(flex.collectionView)) flex.collectionView.trackChanges = true;
        const originalData = {};
        flex.collectionView?.itemsEdited.forEach((item) => {
            originalData[item.id] = { ...item};
        });
        setOriginalFileTypes(originalData);

        trackChanges(fileTypesGridRef, setFileTypesChanges, setIsFileTypesChanged, originalData);
    };

    const initialize = (flex, ref) => {
        ref.current = flex;
    };

    return{
        loading: <p> loading </p>,
        success: (
            <>
            <div style={{ textAlign: 'center', fontSize: 30,  fontWeight: 'bold', margin: '20px' }}>
                <h1>Checklist</h1>
            </div>
            <div>
                <h2>LK Season</h2>
                <FlexGrid 
                    initialized={(flex) => initializeSeason(flex)}
                    className="explorer-grid"
                    itemsSource={seasonDataQuery?.data}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                >
                    <FlexGridColumn header="Ενότητα / Περιγραφή" binding="description" width="*" />
                    <FlexGridColumn header="Έναρξη" binding="dateFrom" width="*" />
                    <FlexGridColumn header="Λήξη" binding="dateTo" width="*" />
                </FlexGrid>
                {isSeasonChanged && (
                    <Button variant="contained" color="primary" onClick={handleSeason}>Save</Button>
                )}
            </div>
            <div>
                <h2>LK Application Type</h2>
                <FlexGrid 
                    initialized={flex => initializeApplicationType(flex)}
                    className="explorer-grid"
                    itemsSource={applicationTypeDataQuery?.data}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                >
                    <FlexGridColumn header="Ενότητα / Περιγραφή" binding="description" width="*" />
                    <FlexGridColumn header="Status" binding="isActive" width="*"/>
                </FlexGrid>
                {isApplicationTypeChanged && (
                    <Button variant="contained" color="primary" onClick={handleApplicationType}>Save</Button>
                )}
            </div>
            <div>
                <h2>LK Events</h2>
                <FlexGrid 
                    initialized={flex => initializeEvents(flex)}
                    className="explorer-grid"
                    itemsSource={eventDataQuery?.data}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                >
                    <FlexGridColumn header="Άθλημα" binding="abbr" width="*" />
                    <FlexGridColumn header="Συνολικοί Αθλητές" binding="totalAthletes" width="*" />
                    <FlexGridColumn header="Status" binding="isActive" width="*" />
                </FlexGrid>
                {isEventsChanged && (
                    <Button variant="contained" color="primary" onClick={handleEvents}>Save</Button>
                )}
            </div>
            <div>
                <h2>LK Product Services</h2>
                <FlexGrid 
                    initialized={flex => initializeProductServices(flex)}
                    className="explorer-grid"
                    itemsSource={productServicesDataQuery?.data}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                >
                    <FlexGridColumn header="Ενότητα / Περιγραφή" binding="description" width="*" />
                    <FlexGridColumn header="Status" binding="unitPrice" width="*" />
                    <FlexGridColumn header="Σχόλια / Λεπτομέρειες" binding="" width="*" />
                </FlexGrid>
                {isProductServicesChanged && (
                    <Button variant="contained" color="primary" onClick={handleProductServices}>Save</Button>
                )}
            </div>
            <div>
                <h2>LK File Types</h2>
                <FlexGrid 
                    initialized={flex => initializeFileTypes(flex)}
                    className="explorer-grid"
                    itemsSource={fileTypesDataQuery?.data}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                >
                    <FlexGridColumn header="Ενότητα / Περιγραφή" binding="descr" width="*" />
                    <FlexGridColumn header="Status" binding="isActive" width="*" />
                    <FlexGridColumn header="Έκδοση από" binding="lblIssuedBy" width="*" />
                    <FlexGridColumn header="Σχόλια" binding="lblComments" width="*" />
                </FlexGrid>
                {isFileTypesChanged && (
                    <Button variant="contained" color="primary" onClick={handleFileTypes}>Save</Button>
                )}
            </div>
            <div>
                <h2>LK Optional Answers</h2>
                <FlexGrid 
                    initialized={flex => initialize(flex, checklistTables)}
                    className="explorer-grid"
                    itemsSource={optionalAnswerDataQuery?.data}
                    isReadOnly={true}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                />
            </div>
            <div>
                <h2>LK Question</h2>
                <FlexGrid 
                    initialized={flex => initialize(flex, checklistTables)}
                    className="explorer-grid"
                    itemsSource={questionDataQuery?.data}
                    isReadOnly={true}
                    autoSizeMode={1}
                    headersVisibility={3}
                    alternatingRowStep={0}
                />
            </div>
            </>
        ),
        error: <p>error</p>
    }[eventDataQuery.status, seasonDataQuery.status, productServicesDataQuery.status];
}

export default ChecklistScreen;

