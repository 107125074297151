import { useMemo, useState } from 'react';
import './Map.css';
import { useQuery } from 'react-query';
import { club } from 'apis';
import { Tooltip, Typography, Fade, Grid, Stack, Divider, Button } from '@mui/material';
import { Circle, ArrowForwardIos } from '@mui/icons-material';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useTranslation } from 'react-i18next';

interface Region {
    id: number;
    name: string;
    pathCoordinates: string;
    athleteCount: number;
    clubCount: number;
    normalizedAthleteCount?: number | null;
    normalizedClubCount?: number | null;
    athletePercentage?: number | null;
    clubPercentage?: number | null;
}

interface MapProps {
    onSelectRegion: (id: number) => void;
}

const Map = (props: MapProps) => {
    const { onSelectRegion } = props;
    const { t } = useTranslation();
    const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);

    const geolocationQuery = useQuery(
        'geolocation',
        async () => {
            const res = await club.getGeolocationClubs();
            const minAthleteCount = Math.min(...res.map((region: Region) => region.athleteCount));
            const maxAthleteCount = Math.max(...res.map((region: Region) => region.athleteCount));
            const totalAthleteCount = res.reduce((acc: number, region: Region) => acc + region.athleteCount, 0);
            const minClubCount = Math.min(...res.map((region: Region) => region.clubCount));
            const maxClubCount = Math.max(...res.map((region: Region) => region.clubCount));
            const totalClubCount = res.reduce((acc: number, region: Region) => acc + region.clubCount, 0);
            const result = res.map((region: Region) => {
                return {
                    ...region,
                    normalizedAthleteCount: (region.athleteCount - minAthleteCount) / (maxAthleteCount - minAthleteCount),
                    normalizedClubCount: (region.clubCount - minClubCount) / (maxClubCount - minClubCount),
                    athletePercentage: (region.athleteCount / totalAthleteCount) * 100,
                    clubPercentage: (region.clubCount / totalClubCount) * 100
                };
            });

            return result;
        },
        { suspense: true }
    );

    const handleSelectRegion = (id: number) => {
        setSelectedRegion(geolocationQuery?.data?.find((region: Region) => region?.id === id));
    };

    const calculateStartAngle = useMemo<number>(() => {
        if (!geolocationQuery?.data) return -135;
        const maxClubPercentage = Math.max(...geolocationQuery?.data?.map((region: Region) => region.clubPercentage));
        const maxAthletePercentage = Math.max(...geolocationQuery?.data?.map((region: Region) => region.athletePercentage));
        const maxPercentage = Math.max(maxClubPercentage, maxAthletePercentage);
        const startAngle = (-180 * maxPercentage) / 100;
        return Math.min(startAngle, -135);
    }, [geolocationQuery]);

    const chartOptions: ApexOptions = {
        chart: {
            type: 'radialBar',
            toolbar: {
                show: false
            }
        },
        colors: ['#35859D'],
        plotOptions: {
            radialBar: {
                startAngle: calculateStartAngle,
                endAngle: -2 * calculateStartAngle,
                hollow: {
                    margin: 0,
                    size: '75%',
                    background: '#fff',
                    // position: 'front',
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 1,
                        blur: 3,
                        opacity: 0.2
                    }
                },
                track: {
                    background: '#fff',
                    strokeWidth: '67%',
                    margin: 0, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: -2,
                        left: 0,
                        blur: 4,
                        opacity: 0.3
                    }
                },

                dataLabels: {
                    show: true,
                    name: {
                        offsetY: -20,
                        show: true,
                        color: '#888',
                        fontSize: '18px'
                    },
                    value: {
                        formatter: function (val: number) {
                            return `${val.toFixed(2).toString()}%`;
                        },
                        color: '#216478',
                        fontSize: '32px',
                        show: true,
                        offsetY: 7
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 1,
                gradientToColors: ['#35859D'],
                inverseColors: true,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: 'round'
        }
    };

    return (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={6}>
                <div className="map-container">
                    <svg viewBox="0 0 1000 1000" preserveAspectRatio="xMidYMid meet">
                        <g>
                            {geolocationQuery?.data?.map((region: Region) => (
                                <Tooltip
                                    key={region?.id}
                                    followCursor
                                    TransitionComponent={Fade}
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 14]
                                                    }
                                                }
                                            ]
                                        }
                                    }}
                                    title={
                                        <>
                                            <Typography>{region?.name}</Typography>
                                        </>
                                    }
                                >
                                    <path
                                        onClick={() => handleSelectRegion(region.id)}
                                        className="region"
                                        d={region?.pathCoordinates}
                                        fill={`rgba(53, 133, 157, ${region.normalizedClubCount})`}
                                        stroke="#000"
                                        strokeWidth="0.3"
                                    />
                                </Tooltip>
                            ))}
                        </g>
                    </svg>
                </div>
            </Grid>
            {selectedRegion ? (
                <Grid item container xs={6} p={2}>
                    <Grid item container justifyContent="center" xs={12}>
                        <Typography variant="h3" fontFamily="inherit" style={{ color: '#888888' }}>
                            {selectedRegion?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <ReactApexChart
                            options={{ ...chartOptions, labels: [t('Athletes')] }}
                            series={[selectedRegion?.athletePercentage ?? 0]}
                            type="radialBar"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ReactApexChart
                            options={{ ...chartOptions, labels: [t('Clubs')] }}
                            series={[selectedRegion?.clubPercentage ?? 0]}
                            type="radialBar"
                        />
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} alignItems="center" flexGrow={1}>
                                <Circle sx={{ color: '#35859D' }} fontSize="small" />
                                <Stack direction="row" spacing={1} justifyContent="space-between" flexGrow={1}>
                                    <Typography variant="subtitle2" fontSize={25} fontFamily="inherit" style={{ color: '#888888' }}>
                                        {t('TotalAthletes')}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={25} fontFamily="inherit" style={{ color: '#35859D' }}>
                                        {selectedRegion?.athleteCount}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ border: '1px dashed #888888' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} alignItems="center" flexGrow={1}>
                                <Circle sx={{ color: '#35859D' }} fontSize="small" />
                                <Stack direction="row" spacing={1} justifyContent="space-between" flexGrow={1}>
                                    <Typography variant="subtitle2" fontSize={24} fontFamily="inherit" style={{ color: '#888888' }}>
                                        {t('TotalClubs')}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={24} fontFamily="inherit" style={{ color: '#35859D' }}>
                                        {selectedRegion?.clubCount}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ border: '1px dashed #888888' }} />
                        </Grid>
                        <Grid item container xs={12} pl={1} py={1}>
                            <Button
                                variant="text"
                                size="large"
                                sx={{ color: '#35859D' }}
                                endIcon={<ArrowForwardIos />}
                                onClick={() => onSelectRegion(selectedRegion.id)}
                            >
                                {t('SeeClubs')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid item container xs={6} justifyContent="center" py={2}>
                    <Typography variant="subtitle1" fontSize={20} fontFamily="inherit" style={{ color: '#888888' }}>
                        {t('SelectRegion')}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default Map;
